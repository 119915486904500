import React from 'react';
import { IconCheck } from '../../../../../assets/Icons';

function AnnualPlanCard({ onSelectPlan, blockButton }) {
  const plansOptions = [
    'Pagamento online',
    'Cardápio QRCode',
    'Análise de acessos',
    'Raio de entrega',
    'Domínio próprio',
    'Todas as demais funcionalidades',
  ];

  function onSelectedPlan() {
    if (blockButton) return;
    onSelectPlan();
  }

  return (
    <div className="plans-container__card annual">
      <div className="plans-container__card-content">
        <h2 className="plans-container__card-title">Plano anual</h2>

        <div className="plans-container__card__price">
          <h1 className="plans-container__card__price-value">R$ 480</h1>
          <h3 className="plans-container__card__price-period">/ano</h3>
        </div>

        <button
          type="button"
          className="plans-container__card-buy-plan-btn"
          onClick={() => onSelectedPlan()}
          disabled={blockButton}
          style={{ opacity: blockButton ? 0.75 : 1 }}
        >
          Assinar plano
        </button>

        <div className="plans-container__card__benefits">
          {plansOptions.map((option, index) => (
            <div className="plans-container__card__benefits-item" key={index}>
              <IconCheck
                size={24}
                opacity={1}
                color={'#000000'}
                stroke={'transparent'}
              />
              <span>{option}</span>
            </div>
          ))}
        </div>
      </div>
      <button
        type="button"
        className="plans-container__card-footer-btn"
        onClick={() =>
          window.open('https://delivery.legal/home#plans', '_blank')
        }
      >
        Ver todas as funcionalidades
      </button>
    </div>
  );
}

export default AnnualPlanCard;
