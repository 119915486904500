import React from 'react';
import DocumentationsFilter from '../../components/DocumentationsFilter/DocumentationsFilter';
import SupportModal from '../../components/SupportModal/SupportModal';
import './Support.scss';

function Support() {
  return (
    <div className="support">
      <DocumentationsFilter useModalColors={false} />
      <SupportModal />
    </div>
  );
}

export default Support;
