import React from 'react';
import TextHeader from '../../SkeletonLoading/TextHeader';

function SettingCard({
  title,
  loaded,
  value,
  onClick,
  booleanValue,
  highlight = false,
}) {
  return (
    <div
      className="bg-white shadow-sm d-flex flex-column p-2 rounded"
      onClick={() => onClick && onClick()}
    >
      <button className="btn btn-primary-fill p-0 fs-6">{title}</button>
      <span
        className={`d-block w-100 text-center fw-bold ${
          highlight ? (booleanValue ? 'text-success' : 'text-danger') : ''
        }`}
      >
        {loaded ? value : <TextHeader />}
      </span>
    </div>
  );
}

export default SettingCard;
