import React, { useContext, useEffect, useState } from 'react';
import SpringModal from '../../shared/Modal/Modal';
import ProductInfs from './ProductInfs/ProductInfs';
import {
  deepClone,
  equals,
  getOpeningHours,
  maskCurrencyBRLWithoutSymbol,
  returnUniqueID,
  sortBy,
  useWindowDimensions,
  verifyIsUniqueID,
  verifyStoreIsOpen,
} from '../../Utils/Index';
import Complements from './Complements/Complements';
import {
  ComplementsContext,
  MenuContext,
  StoreContext,
} from '../MenuProvider/MenuProvider';
import { toast } from 'react-toastify';
import EventEmitter from '../.../../../services/Event';

export default function ProductDetails({
  productSelected,
  completsProduct,
  openModal,
  handleModal,
  canOrderParam,
}) {
  const [product, setProduct] = useState(null);

  const [loadedInfs, setLoadedInfs] = useState(false);
  const [
    disableButtonToInserProductInCart,
    setDisableButtonToInserProductInCart,
  ] = useState(true);

  const [complementsProduct, setComplementsProduct] = useState([]);

  const [totalPrice, setTotalPrice] = useState({
    value: 0,
    formated: ' 0,00',
    with_qty: 0,
    with_qty_formated: ' 0,00',
  });

  const [qtyProduct, setQtyProduct] = useState(1);

  const { width } = useWindowDimensions();

  const { productsOrders, setProductsOrders } = useContext(MenuContext);
  const { complementsProductSelected } = useContext(ComplementsContext);
  const { storeSettingsProvider } = useContext(StoreContext);

  useEffect(() => {
    setProduct(productSelected);
    return () => setProduct(null);
  }, [productSelected]);

  useEffect(() => {
    if (!completsProduct) return;
    setComplementsProduct(completsProduct);
    setDisableButtonToInserProductInCart(false);
    return () => {
      setComplementsProduct([]);
      setDisableButtonToInserProductInCart(true);
    };
  }, [completsProduct]);

  useEffect(() => {
    if (complementsProduct) setLoadedInfs(product && complementsProduct);
    else setLoadedInfs(Boolean(product));
    return () => setLoadedInfs(false);
  }, [product, complementsProduct]);

  useEffect(() => {
    if (qtyProduct < 1) {
      setQtyProduct(1);
      return;
    }
    if (product) recalculateOrderPrice();
  }, [qtyProduct]);

  useEffect(() => {
    setQtyProduct(1);
    setTotalPrice({
      value: productSelected.preco,
      formated: maskCurrencyBRLWithoutSymbol(productSelected.preco),
      with_qty: productSelected.preco,
      with_qty_formated: maskCurrencyBRLWithoutSymbol(productSelected.preco),
    });
  }, [openModal]);

  async function storeAvailableToCreateOrder() {
    const { store, hours_store } = storeSettingsProvider.settings;
    const { error, result } = await verifyStoreIsOpen(store.codigo);

    if (error) {
      toast.error(
        'Ocorreu um erro ao verificar o status de funcionamento do estabelecimento, tente novamente ou entre em contato conosco.'
      );
      return false;
    }

    if (!result) {
      const openingHours = getOpeningHours(hours_store);
      toast.warning(
        `O estabelecimento está fechado, pedidos so serão aceitos entre ${openingHours.open} á ${openingHours.closed}.`,
        { autoClose: 0 }
      );
      return false;
    }

    return true;
  }

  function updateChangesOnComplement(groupComplement) {
    const response = complementsProduct.map((group) => {
      if (group.cod_produto_opcoes === groupComplement.cod_produto_opcoes)
        group = groupComplement;
      return group;
    });
    setComplementsProduct(response);
  }

  function updateChangesOnProduct(product) {
    setProduct(product);
    recalculateOrderPrice();
  }

  function recalculateOrderPrice() {
    product.qty = qtyProduct;

    let price = 0;
    if (product.count_price) price = product.count_price.number * qtyProduct;
    else price = product.preco * qtyProduct;

    setTotalPrice({
      value: product.preco,
      formated: maskCurrencyBRLWithoutSymbol(product.preco),
      with_qty: price,
      with_qty_formated: maskCurrencyBRLWithoutSymbol(price),
    });
    setProduct(product);
  }

  async function callInsertProductOnCart() {
    setDisableButtonToInserProductInCart(true);
    if (!(await storeAvailableToCreateOrder())) return;
    if (complementsProduct) {
      if (
        !complementsProduct.every((groupComplement) => groupComplement.is_valid)
      ) {
        setDisableButtonToInserProductInCart(false);
        toast.error(
          'Quantidade miníma de complementos para este produto não atingida.',
          { position: 'bottom-left', autoClose: 8000 }
        );
      } else insertProductOnCart();
    } else insertProductOnCart();
  }

  function insertProductOnCart() {
    const newProductToInsertInCart = getNewProductInformations();
    const productsInCart = getCart();

    if (!productsInCart.length) {
      productsInCart.push(newProductToInsertInCart);
      updateCart(productsInCart);
      return;
    }

    const sameProductInCart = productsInCart.filter(
      (product) => product.codigo === newProductToInsertInCart.codigo
    );
    if (!sameProductInCart.length) {
      productsInCart.push(newProductToInsertInCart);
      updateCart(productsInCart);
      return;
    }

    if (sameProductInCart.length === 1) {
      verifyHasSameComplements(newProductToInsertInCart);
      return;
    }

    searchForSameProductInCart(sameProductInCart, newProductToInsertInCart);
  }

  function getNewProductInformations() {
    return {
      product_unique_id: getUniqueIdForProduct(),
      codigo: product.codigo,
      qtd: qtyProduct,
      obs: '',
      name: product.nome,
      price: calculeProductPrice(product.preco, qtyProduct),
      image: product.image,
      complementos: getAllComplementsForProdut(),
    };
  }

  function getUniqueIdForProduct() {
    let id = returnUniqueID();
    const listOfAllIdsGenerated = productsOrders.map(
      (product) => product.product_unique_id
    );
    if (!listOfAllIdsGenerated.length) return id;
    id = verifyIsUniqueID(listOfAllIdsGenerated, id);
    return id;
  }

  function getAllComplementsForProdut() {
    if (!complementsProductSelected.length) return [];
    const complements = sortBy(
      complementsProductSelected.find(
        (complement) => complement.id_product === product.codigo
      ).complement_selected,
      'id_complement'
    );
    if (!complements) return [];
    return complements;
  }

  function getCart() {
    return deepClone(productsOrders);
  }

  function updateCart(cartUpdated) {
    setProductsOrders(cartUpdated);
    EventEmitter.emit('update-cart', cartUpdated);
    toast.success('Item adicionado ao carrinho.', { position: 'bottom-left' });
    handleModal();
  }

  function verifyHasSameComplements(productToAddInCart) {
    const productsInCart = getCart();
    const indexSameProductInCart = productsInCart.findIndex(
      (product) => product.codigo === productToAddInCart.codigo
    );

    const idsComplementsProductOnCart = sortBy(
      productsInCart[indexSameProductInCart].complementos.map((complement) => {
        return { id_complement: complement.cod_complemento };
      }),
      'id_complement'
    );
    const idsComplementsProductToAdd = sortBy(
      productToAddInCart.complementos.map((complement) => {
        return { id_complement: complement.cod_complemento };
      }),
      'id_complement'
    );

    if (!equals(idsComplementsProductOnCart, idsComplementsProductToAdd)) {
      productsInCart.push(productToAddInCart);
      updateCart(productsInCart);
      return;
    }

    updateProductInCart(indexSameProductInCart);
    return;
  }

  function updateProductInCart(indexProductToUpdate) {
    let productsInCart = deepClone(productsOrders);
    const productToUpdate = productsInCart[indexProductToUpdate];
    productToUpdate.qtd += qtyProduct;
    productToUpdate.price = calculeProductPrice(
      productToUpdate.price.value,
      productToUpdate.qtd
    );
    productToUpdate.complementos = updateComplementQuantity(
      productToUpdate.complementos,
      productToUpdate.qtd
    );
    productsInCart[indexProductToUpdate] = productToUpdate;
    updateCart(productsInCart);
  }

  function calculeProductPrice(price, productQty) {
    return {
      value: price,
      formated: maskCurrencyBRLWithoutSymbol(price),
      with_qty: price * productQty,
      with_qty_formated: maskCurrencyBRLWithoutSymbol(price * productQty),
    };
  }

  function updateComplementQuantity(complements, newQuantity) {
    const newComplementsQuantity = complements.map((complement) => {
      complement.qtd = newQuantity;
      const priceUpdatedWithQty = complement.price.value * newQuantity;
      complement.price.with_qty = priceUpdatedWithQty;
      return complement;
    });
    return newComplementsQuantity;
  }

  function searchForSameProductInCart(productsWithSameId, productToAddInCart) {
    if (!productToAddInCart.complementos.length) {
      const productWithSameIdWihtoutComplement = productsWithSameId.find(
        (product) => !product.complementos.length
      );
      if (!productWithSameIdWihtoutComplement) {
        const cart = getCart();
        cart.push(productToAddInCart);
        updateCart(cart);
        return;
      }
      updateProductInCart(
        productsOrders.findIndex(
          (product) =>
            product.product_unique_id ===
            productWithSameIdWihtoutComplement.product_unique_id
        )
      );
      return;
    }

    const complementsFromProductToAdd = sortBy(
      productToAddInCart.complementos.map((complement) => {
        return { cod_complemento: complement.cod_complemento };
      }),
      'cod_complemento'
    );
    let complementsInProduct = [];

    productsWithSameId.forEach((product) => {
      complementsInProduct.push(
        sortBy(
          product.complementos.map((complement) => {
            return { cod_complemento: complement.cod_complemento };
          }),
          'cod_complemento'
        )
      );
    });

    let hasProductWithSameComplements = false;
    for (let index = 0; index < complementsInProduct.length; index++) {
      if (equals(complementsInProduct[index], complementsFromProductToAdd)) {
        updateProductInCart(
          productsOrders.findIndex(
            (product) =>
              product.product_unique_id ===
              productsWithSameId[index].product_unique_id
          )
        );
        hasProductWithSameComplements = true;
        break;
      }
    }

    if (hasProductWithSameComplements) return;

    const cart = getCart();
    cart.push(productToAddInCart);
    updateCart(cart);
  }

  return (
    <SpringModal handleOpen={openModal} handleClose={() => handleModal(false)}>
      <div className="position-relative w-100 h-75 overflow-hidden rounded-3">
        <ProductInfs product={product} close={() => handleModal(false)} />

        {complementsProduct.length > 0 && (
          <div
            className="overflow-y-auto d-flex flex-column"
            style={{ maxHeight: 300 }}
          >
            {complementsProduct.map((group, index) => {
              return (
                <Complements
                  key={index}
                  idProduct={product.codigo}
                  qty={qtyProduct}
                  complements={group}
                  updateComplement={updateChangesOnComplement}
                  updateProduct={updateChangesOnProduct}
                />
              );
            })}
          </div>
        )}

        {canOrderParam && (
          <div className="w-100 p-2 hstack justify-content-between gap-2">
            <div className="box-count">
              <button
                className="down-count"
                onClick={() => setQtyProduct(qtyProduct - 1)}
                disabled={!Boolean(qtyProduct > 0)}
              >
                -
              </button>
              {qtyProduct}
              <button
                className="up-count"
                onClick={() => setQtyProduct(qtyProduct + 1)}
              >
                +
              </button>
            </div>

            <button
              className="btn btn-outline-primary"
              onClick={() => callInsertProductOnCart()}
              disabled={disableButtonToInserProductInCart}
            >
              <div className="hstack gap-2">
                <span>Adicionar</span>
                {loadedInfs && <span>R${totalPrice.with_qty_formated}</span>}
              </div>
            </button>
          </div>
        )}
      </div>
    </SpringModal>
  );
}
