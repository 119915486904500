import React, { useEffect, useState } from 'react';
import NoProductImage from '../../../assets/Images/no-product-image.png';

export default function ProductInfs({ product, close }) {
  const [image, setImage] = useState(NoProductImage);

  useEffect(() => {
    if (product.image) setImage(product.image);
  }, [product]);

  return (
    <div>
      <div
        className="hstack position-absolute max-w-fit p-1 bg-white rounded-5 bg-opacity-75"
        style={{ right: 4, top: 4 }}
      >
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => close(false)}
        ></button>
      </div>

      <img
        className="w-100 h-100"
        src={image}
        alt="Imagem do produto"
        onError={() => setImage(NoProductImage)}
      />

      <div className="vstack p-2 ">
        <div className="vstack">
          <h1 className="fs-3 fw-bold m-0 p-0">{product.nome}</h1>
          <span className="fs-7 text-secondary">{product.descricao}</span>
          {Boolean(product.peso) && (
            <span className="hstack gap-1 fs-7 text-secondary">
              <span>Peso:</span>
              {<span>{product.peso}</span>}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
