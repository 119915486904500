import React, { useEffect, useState } from 'react';
import './Plans.scss';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { IconCheck, IconError } from '../../assets/Icons';

import Slider from 'react-slick';

export default function Plans() {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows([
      createData('Gestor de pedidos', true, true, true, false),
      createData('Integração com iFood', false, true, true, true),
      createData('Sistema de troco', true, true, true, false),
      createData('Raio de entrega', true, true, true, false),
      createData('Horário personalizado', true, true, true, false),
      createData('Análise de acessos', false, false, true, false),
      createData('Cupom de desconto', true, true, true, false),
      createData('Cardápio QRCode', false, false, true, false),
      createData('Personalizar estabelecimento', true, true, true, false),
      createData('Pagamento online', false, false, true, true),
      createData('Domínio próprio', false, false, true, false),
    ]);
  }, []);

  function createData(
    name,
    enabledInDemo,
    enabledInMonthPlan,
    enabledInAnualPlan,
    comingsoon
  ) {
    const iconNotEnabled = (
      <IconError color={'#F10542'} stroke={'#3E3E3E'} opacity={0.9} />
    );
    const iconEnabled = (
      <IconCheck color={'#02B345'} stroke={'#3E3E3E'} opacity={0.9} />
    );

    const iconEnableOrNotForDemo = enabledInDemo ? iconEnabled : iconNotEnabled;
    const iconEnableOrNotForMonth = enabledInMonthPlan
      ? iconEnabled
      : iconNotEnabled;
    const iconEnableOrNotForAnual = enabledInAnualPlan
      ? iconEnabled
      : iconNotEnabled;
    return {
      name,
      iconEnableOrNotForDemo,
      iconEnableOrNotForMonth,
      iconEnableOrNotForAnual,
      comingsoon,
    };
  }

  const plans = [
    {
      title: 'Anual',
      price: 'R$ 79,90',
      price_payment: 'no pagamento anual',
      header_color: '#F10542',
      plan_options: [
        'Gestor de pedidos',
        'Integração com iFood',
        'Sistema de troco',
        'Raio de entrega',
        'Horário personalizado',
        'Análise de acessos',
        'Cupom de desconto',
        'Cardápio QRCode',
        'Personalizar estabelecimento',
        'Pagamento online',
        'Domínio próprio',
      ],
    },
    {
      title: 'Mensal',
      price: 'R$ 89,90',
      price_payment: 'no pagamento mensal',
      header_color: '#3E3E3E',
      plan_options: [
        'Gestor de pedidos',
        'Integração com iFood',
        'Sistema de troco',
        'Raio de entrega',
        'Horário personalizado',
        'Cupom de desconto',
        'Personalizar estabelecimento',
      ],
    },
    {
      title: 'Demo',
      price: 'R$ 0,00',
      price_payment: 'grátis no primeiro mês',
      header_color: '#3E3E3E',
      plan_options: [
        'Gestor de pedidos',
        'Sistema de troco',
        'Raio de entrega',
        'Horário personalizado',
        'Cupom de desconto',
        'Personalizar estabelecimento',
      ],
    },
  ];

  const settings = {
    className: 'slider variable-width',
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: null,
    prevArrow: null,
  };

  return (
    <div className="plans">
      <div className="plans-container">
        <div className="plans__text-container">
          <h3 className="plans__text-container-title">Conheça nossos planos</h3>
          <span className="plans__text-container__subtitle">
            Venda sem se preocupar com limites, taxas e comissões, aqui a taxa é{' '}
            <strong className="plans__text-container__subtitle-price-spotlight">
              R$ 0,00
            </strong>
          </span>
        </div>
        <div className="plans__table">
          <TableContainer style={{ overflow: 'inherit' }}>
            <Table style={{ width: '100%' }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    <div className="plans__table-custom-header">
                      <div className="plans__table-custom-header__title-content">
                        <h4 className="plans__table-custom-header__title-content-title">
                          Demo
                        </h4>
                      </div>
                      <div className="plans__table-custom-header__information">
                        <h4 className="plans__table-custom-header__information__price">
                          R$ 0,00{' '}
                        </h4>
                        <span className="plans__table-custom-header__information-type">
                          grátis no 1º mês
                        </span>
                      </div>
                      <div className="plans__table-custom-header-border-bottom"></div>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="plans__table-custom-header spotlight-card">
                      <div style={{ width: '100%', position: 'relative' }}>
                        <div className="plans__table-custom-header__title-content spotlight-plan">
                          <h4 className="plans__table-custom-header__title-content-title">
                            Anual
                          </h4>
                        </div>
                        <div className="plans__table-custom-header__information">
                          <h4 className="plans__table-custom-header__information__price">
                            R$ 79,90{' '}
                            <small className="plans__table-custom-header__information__price-recorrence">
                              /mês
                            </small>
                          </h4>
                          <span className="plans__table-custom-header__information-type">
                            no pagamento anual
                          </span>
                        </div>
                        <div
                          className="plans__table-custom-header-border-bottom"
                          style={{ bottom: 5, left: '7%' }}
                        ></div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="plans__table-custom-header">
                      <div className="plans__table-custom-header__title-content">
                        <h4 className="plans__table-custom-header__title-content-title">
                          Mensal
                        </h4>
                      </div>
                      <div className="plans__table-custom-header__information">
                        <h4 className="plans__table-custom-header__information__price">
                          R$ 89,90{' '}
                          <small className="plans__table-custom-header__information__price-recorrence">
                            /mês
                          </small>
                        </h4>
                        <span className="plans__table-custom-header__information-type">
                          no pagamento mensal
                        </span>
                      </div>
                      <div className="plans__table-custom-header-border-bottom"></div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: index % 2 !== 0 ? '#F8F8F8' : '#FFFFFF',
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}{' '}
                      {row.comingsoon && (
                        <span className="plans-coming-soon">_breve</span>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ maxWidth: 280, width: '25%' }}
                      align="center"
                    >
                      <div style={{ zIndex: 999 }}>
                        {row.iconEnableOrNotForDemo}
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        maxWidth: 280,
                        width: '25%',
                        backgroundColor: 'white',
                        zIndex: 999,
                      }}
                      align="center"
                    >
                      <div style={{ zIndex: 999 }}>
                        {row.iconEnableOrNotForAnual}
                      </div>
                    </TableCell>
                    <TableCell
                      style={{ maxWidth: 280, width: '25%' }}
                      align="center"
                    >
                      <div style={{ zIndex: 999 }}>
                        {row.iconEnableOrNotForMonth}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="slider-mobile">
          <Slider
            {...settings}
            style={{ cursor: 'pointer', maxHeight: '540px' }}
          >
            {plans.map((plan, index) => {
              return (
                <div key={index} className="card-mobile">
                  <div className="plans__table-custom-header">
                    <div
                      className="plans__table-custom-header__title-content"
                      style={{ backgroundColor: plan.header_color }}
                    >
                      <h4 className="plans__table-custom-header__title-content-title">
                        {plan.title}
                      </h4>
                    </div>
                    <div className="plans__table-custom-header__information">
                      <h4 className="plans__table-custom-header__information__price">
                        {plan.price}
                      </h4>
                      <span className="plans__table-custom-header__information-type">
                        {plan.price_payment}
                      </span>
                    </div>
                    <div className="plans__table-custom-header-border-bottom"></div>
                  </div>
                  <ul className="plan-options">
                    {plan.plan_options.map((option, optionIndex) => {
                      return (
                        <li key={optionIndex} className="plan-option">
                          {option}{' '}
                          {option === 'Integração com iFood' ||
                          option === 'Pagamento online' ? (
                            <span className="plans-coming-soon">_breve</span>
                          ) : null}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
