import React from 'react';
import { IconCheck } from '../../../../../assets/Icons';

function MonthlyPlanCard({ onSelectPlan, blockButton }) {
  const plansOptions = [
    'Integração com iFood',
    'Sistema de troco',
    'Raio de entrega',
    'Horário personalizado',
    'Cupom de desconto',
    'Personalizar estabelecimento',
  ];

  function onSelectedPlan() {
    if (blockButton) return;
    onSelectPlan();
  }

  return (
    <div className="plans-container__card monthly">
      <div className="plans-container__card-content">
        <h2 className="plans-container__card-title monthly-dark-color">
          Plano mensal
        </h2>

        <div className="plans-container__card__price monthly-dark-color">
          <h1 className="plans-container__card__price-value">R$ 50</h1>
          <h3 className="plans-container__card__price-period">/mês</h3>
        </div>

        <button
          type="button"
          className="plans-container__card-buy-plan-btn monthly-primary-color"
          onClick={() => onSelectedPlan()}
          disabled={blockButton}
          style={{ opacity: blockButton ? 0.75 : 1 }}
        >
          Assinar plano
        </button>

        <div className="plans-container__card__benefits monthly-dark-color">
          {plansOptions.map((option, index) => (
            <div className="plans-container__card__benefits-item" key={index}>
              <IconCheck
                size={24}
                opacity={1}
                color={'#F10542'}
                stroke={'transparent'}
              />
              <span>{option}</span>
            </div>
          ))}
        </div>
      </div>
      <button
        type="button"
        className="plans-container__card-footer-btn monthly-footer-btn"
        onClick={() =>
          window.open('https://delivery.legal/home#plans', '_blank')
        }
      >
        Ver todas as funcionalidades
      </button>
    </div>
  );
}

export default MonthlyPlanCard;
