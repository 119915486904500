import React, { useState } from 'react';
import ClientAuthMethods from './ClientAuthMethods';
import SignInClient from './SignInClient';
import CreateClientAccount from './CreateClientAccount';

function AuthenticateClient({ back, authenticated }) {
  const [isUnknown, setIsUnknown] = useState(true);
  const [signIn, setSignIn] = useState(true);

  function handleAuthMethod(isSignIn) {
    setSignIn(isSignIn);
    setIsUnknown(false);
  }

  return isUnknown ? (
    <ClientAuthMethods
      sign={() => handleAuthMethod(true)}
      createAccount={() => handleAuthMethod(false)}
    />
  ) : signIn ? (
    <SignInClient
      backStep={() => setIsUnknown(true)}
      onAuthenticate={() => authenticated()}
    />
  ) : (
    <CreateClientAccount
      backToSignIn={() => setIsUnknown(true)}
      onCreateAccount={() => authenticated()}
    />
  );
}

export default AuthenticateClient;
