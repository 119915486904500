import React from 'react';
import LoadingGif from '../../../../../assets/loading.gif';

function Loader({ title }) {
  return (
    <div className="subscription-process-flex-column subscription-process__pix-loading">
      <span>
        {title
          ? title
          : 'Carregando informações do pagamento, por favor aguarde.'}
      </span>
      <img src={LoadingGif} width={50} alt="Icone de conteúdo carregando" />
    </div>
  );
}

export default Loader;
