import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { login } from '../../services/AuthService';
import {
  getItemInBrowserStorage,
  removeItemFromSession,
  saveInBrowserStorage,
} from '../../Utils/Index';
import {
  SalesRepresentative,
  StoreOwner,
} from '../Provider/AuthenticationProvider';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Scope } from '@unform/core';
import InputV2 from '../../shared/InputV2/InputV2';
import DeliveryLogo from '../../assets/Images/logo/logo.png';

function StoreLogin({ handleFirstAccess, recoverPassword }) {
  const formRef = useRef();

  const [secundsToRetry, setSecundsToRetry] = useState('30 segundos');
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const [loading, setLoading] = useState(false);
  const [blockCallLogin, setBlockCallLogin] = useState(false);
  const [displayPassword, setDisplayPassword] = useState(false);

  const [retryLogin, setRetryLogin] = useState(1);
  const [countdownTimeout, setCountdownTimeout] = useState(0);
  const [timeoutToCallLogin, setTimeoutToCallLogin] = useState(0);

  const { setRepresentativeData } = useContext(SalesRepresentative);
  const { setStoreOwnerData } = useContext(StoreOwner);

  const history = useHistory();
  const recaptchaRef = useRef(null);

  useEffect(() => {
    const qtyErrors = getItemInBrowserStorage('TIMEOUT_LOGIN', false);
    if (qtyErrors) countToRetry(qtyErrors);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  async function handleSubmitForm(data) {
    try {
      const schema = Yup.object().shape({
        login: Yup.object().shape({
          email: Yup.string()
            .required('E-mail é obrigatório')
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              'E-mail inválido'
            ),
          senha: Yup.string().required('Senha é obrigatória'),
        }),
      });

      const isValid = await schema.validate(data, { abortEarly: false });
      if (!isValid) {
        formRef.current.setErrors({});
        return;
      }
      callLogin();
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  async function callLogin() {
    const {
      login: { email, senha },
    } = formRef.current.getData();
    setLoading(true, { email, senha, recaptcha: recaptchaToken });
    login(email, senha)
      .then((response) => {
        const { result } = response;
        if (result.cod_estabelecimento) {
          handleUserDataAndNavToStore(result);
          return;
        }
        handleRepresentative(result);
      })
      .catch((error) => {
        setLoading(false);
        error.status === 401
          ? invalidData()
          : toast.error(
              'Ocorreu um erro ao realizar login, tente novamente ou entre em contato conosco.'
            );
      });
  }

  function handleUserDataAndNavToStore(params) {
    setStoreOwnerData(params);
    const refirUrl = params.configuracao_completa
      ? '/painel/cardapio'
      : '/primeiro-acesso';
    history.push(refirUrl);
    return;
  }

  function handleRepresentative(params) {
    setRepresentativeData(params);
    history.push('/representante/faturamento');
  }

  function countToRetry(qtyErrors) {
    let totalInterval = parseInt(qtyErrors * 30);
    setSecundsToRetry(`${totalInterval} segundos`);
    setBlockCallLogin(true);
    saveInBrowserStorage('TIMEOUT_LOGIN', qtyErrors, false);
    clearInterval(countdownTimeout);
    const interval = setInterval(() => {
      totalInterval--;
      setSecundsToRetry(`${totalInterval} segundos`);
      if (totalInterval === 0) {
        clearInterval(countdownTimeout);
        setBlockCallLogin(false);
        removeItemFromSession('TIMEOUT_LOGIN', false);
      }
    }, 1000);
    setCountdownTimeout(interval);
  }

  function invalidData(showToast = true) {
    setRetryLogin(retryLogin + 1);
    if (retryLogin === 3) {
      toast.warn(
        "Caso precise de ajuda para acessar sua conta, clique em 'recuperar senha' para reconfigurar seu acesso",
        { autoClose: 7000 }
      );
      countToRetry(timeoutToCallLogin + 1);
      setRetryLogin(1);
      setTimeoutToCallLogin(timeoutToCallLogin + 1);
      return;
    }
    if (showToast) toast.error('Dados inválidos, por favor tente novamente');
  }

  return (
    <div className="vstack w-100 h-100 align-items-center justify-content-center">
      <img
        src={DeliveryLogo}
        alt="Logotipo do Delivery.Legal"
        width={300}
        className="mb-5"
      />

      <h3 className="fw-bold fs-3 text-center mb-3">
        Bem vindo, faça login para continuar
      </h3>

      <Form
        ref={formRef}
        onSubmit={(path) => handleSubmitForm(path)}
        className={`w-100 gap-3 d-flex flex-column ${
          loading ? 'pe-none opacity-75' : ''
        }`}
      >
        <Scope path="login">
          <InputV2
            name="email"
            type="email"
            label="E-mail"
            placeholder="E-mail"
            maxLength={40}
            autoComplete="email"
            disabled={loading}
          />

          <InputV2
            name="senha"
            label="Senha"
            containerClass="position-relative"
            maxLength={75}
            autoComplete="current-password"
            type={displayPassword ? 'text' : 'password'}
            disabled={loading}
          >
            <button
              type="button"
              className="z-2 btn btn-primary-fill p-0 m-0 position-absolute d-flex align-items-center justify-content-center p-1 "
              style={{ top: 30, right: 4 }}
              onClick={() => setDisplayPassword(!displayPassword)}
            >
              <span className="material-symbols-outlined fs-5">
                {displayPassword ? 'visibility' : 'visibility_off'}
              </span>
            </button>
          </InputV2>
        </Scope>

        {blockCallLogin && (
          <div className="hstack w-100 gap-1">
            <span> Aguarde {secundsToRetry} para tentar novamente</span>
          </div>
        )}

        <div className="w-100 hstack justify-content-center py-2">
          <ReCAPTCHA
            ref={recaptchaRef}
            size="normal"
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_LOGIN}
            onExpired={() => setRecaptchaToken()}
            onError={() => setRecaptchaToken()}
            onChange={(token) => setRecaptchaToken(token)}
          ></ReCAPTCHA>
        </div>

        <div className="vstack gap-2">
          <button
            type="submit"
            disabled={blockCallLogin || !recaptchaToken}
            className="btn btn-primary"
          >
            Entrar
          </button>

          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => handleFirstAccess(true)}
          >
            Criar nova conta
          </button>

          <button
            type="button"
            className="btn btn-fill-primary"
            onClick={() => recoverPassword()}
          >
            Recuperar senha
          </button>
        </div>
      </Form>
    </div>
  );
}

export default StoreLogin;
