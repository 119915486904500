import React, { useState, useEffect } from 'react';
import './RepresentativeHeader.scss';

import DeliveryLogo from '../../assets/Images/logo/logo.png';
import SmallDeliveryLogo from '../../assets/Images/logo/small_logo.png';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from 'react-router-dom';

import BillingData from '../BillingData/BillingData';
import SendProposal from '../SendProposal/SendProposal';
import ProposalHistory from '../ProposalHistory/ProposalHistory';
import { useWindowDimensions } from '../../Utils/Index';
import HamburgerIcon from '../../shared/HamburgerIcon/HamburgerIcon';
import RepresentativeAccountDetails from '../RepresentativeAccountDetails/RepresentativeAccountDetails';
import SalesRepresentativeProvider from '../SalesRepresentativeProvider/SalesRepresentativeProvider';
import FinancialHistory from '../FinancialHistory/FinancialHistory';

function RepresentativeHeader() {
  const [routes, setRoutes] = useState([
    {
      idRoute: 0,
      redirectToComponent: true,
      active: true,
      path: '/representante/faturamento',
      label: 'Faturamento',
      component: <BillingData />,
    },
    {
      idRoute: 1,
      redirectToComponent: true,
      active: false,
      path: '/representante/proposta',
      label: 'Enviar proposta',
      component: <SendProposal />,
    },
    {
      idRoute: 2,
      redirectToComponent: true,
      active: false,
      path: '/representante/historico-envios',
      label: 'Histórico de envios',
      component: <ProposalHistory />,
    },
    {
      idRoute: 3,
      redirectToComponent: true,
      active: false,
      path: '/representante/historico-financeiro',
      label: 'Histórico Financeiro',
      component: <FinancialHistory />,
    },
    {
      idRoute: 4,
      redirectToComponent: true,
      active: false,
      path: '/representante/meus-dados',
      label: 'Meus Dados',
      component: <RepresentativeAccountDetails />,
    },
    {
      idRoute: 5,
      redirectToComponent: false,
      active: false,
      path: null,
      label: 'Sair',
      component: null,
    },
  ]);

  const [logoDeliveryLegal, setLogoDeliveryLegal] = useState(DeliveryLogo);

  const [openMenu, setOpenMenu] = useState(true);
  const [mobileLayout, setMobileLayout] = useState(false);

  const history = useHistory([]);
  const { width } = useWindowDimensions();

  useEffect(() => {
    const currenrRoute = routes.find(
      (route) => route.path === window.location.pathname
    );
    if (!currenrRoute) {
      history.push('/representante/faturamento');
      navegateToRoute(0);
      return;
    }
    navegateToRoute(currenrRoute.idRoute);
  }, []);

  useEffect(() => {
    setMobileLayout(width <= 768);
    if (width <= 768) {
      configureHeaderLayout(true);
      return;
    }
    configureHeaderLayout(false);
  }, [width]);

  function navegateToRoute(routeID) {
    if (routeID === 5) {
      loggout();
      return;
    }

    const updatedActivatedRoute = routes.map((route) => {
      route.active = false;
      if (route.idRoute === routeID) route.active = true;
      return route;
    });
    setRoutes(updatedActivatedRoute);
  }

  function loggout() {
    localStorage.removeItem('USER_INFO');
    localStorage.removeItem('TOKEN');
    sessionStorage.removeItem('ID_REPRESENTANTE');
    history.push(`/login`);
  }

  function configureHeaderLayout(isMobile) {
    if (!isMobile) {
      setOpenMenu(true);
      setLogoDeliveryLegal(DeliveryLogo);
      const updatedFieldsInHeader = routes.map((route) => {
        if (!route.redirectToComponent && route.enabled) route.enabled = false;
        return route;
      });
      setRoutes(updatedFieldsInHeader);
      return;
    }

    setLogoDeliveryLegal(SmallDeliveryLogo);
    setOpenMenu(false);
    const updatedFieldsInHeader = routes.map((route) => {
      if (!route.redirectToComponent && !route.enabled) route.enabled = true;
      return route;
    });
    setRoutes(updatedFieldsInHeader);
  }

  return (
    <SalesRepresentativeProvider>
      <Router>
        <div
          className={`representative-header${
            mobileLayout ? `-${openMenu ? 'open' : 'closed'}` : ''
          }`}
        >
          <div
            className="representative-header-hamburguer-icon"
            onClick={() => setOpenMenu(!openMenu)}
          >
            <HamburgerIcon initialValue={openMenu} />
          </div>
          <div
            className={`representative-header__content-${
              openMenu ? 'open' : 'closed'
            }`}
          >
            <div className="representative-header__left-section">
              <img
                className="representative-header__left-section-logo"
                src={logoDeliveryLegal}
                alt="Logotipo do Delivery Legal"
              />
              <div className="representative-header__left-section__routes">
                {routes.map((route, index) => {
                  return (
                    <React.Fragment key={index}>
                      {route.redirectToComponent ? (
                        <Link
                          className={`representative-header__left-section__routes-${
                            route.active ? 'route-active' : 'route'
                          }`}
                          to={route.path}
                          onClick={() => navegateToRoute(route.idRoute)}
                          title={route.label}
                        >
                          {route.label}
                        </Link>
                      ) : (
                        <button
                          className="representative-header__left-section__routes-route exit"
                          onClick={() => navegateToRoute(route.idRoute)}
                        >
                          {route.label}
                        </button>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="representative-content">
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={false}
                children={route.component}
              />
            ))}
          </Switch>
        </div>
      </Router>
    </SalesRepresentativeProvider>
  );
}

export default RepresentativeHeader;
