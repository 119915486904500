import React, { useState } from 'react';
import './StoreAuthentication.scss';
import WomanSmiling from '../../assets/Images/StoreLogin/woman_smiling.png';
import StoreRecoverPassword from './RecoverPassword';
import StoreCreateAccount from './CreateAccount';
import StoreLogin from './Login';

function StoreAuthentication() {
  const [step, setStep] = useState(0);

  return (
    <div className="store-login">
      <div className="w-100 h-100 p-3 align-items-center d-flex">
        {step === 0 && (
          <StoreLogin
            recoverPassword={() => setStep(1)}
            handleFirstAccess={() => setStep(2)}
          />
        )}
        {step === 1 && <StoreRecoverPassword goBack={() => setStep(0)} />}
        {step === 2 && <StoreCreateAccount goBack={() => setStep(0)} />}
      </div>
      <div className="store-login__image">
        <div className="store-login__image__element"></div>
        <div className="store-login__image__shadow"></div>
        <img src={WomanSmiling} alt="Mulher sorrindo" />
      </div>
    </div>
  );
}

export default StoreAuthentication;
