import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import './Produtos.scss';
import {
  getAllProducts,
  updateProduct,
  deleteProduct,
  deleteCategory,
} from '../../services/Products';
import { getAllCategories } from '../../services/Categories';
import {
  deepClone,
  getItemInBrowserStorage,
  maskCurrencyBRL,
  maskCurrencyBRLWithSymbolString,
  removeMaskPrice,
  useWindowDimensions,
} from '../../Utils/Index';
import EventEmitter from '../../services/Event';

import Sidebar from 'react-sidebar';
import CreateProduct from './createProduct/CreateProduct';
import DropDown from '../../shared/DropDown/DropDown';
import { useFormProduct } from './ContextProduct/ContextProduct';
import { Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';
import ProductsSkeleton from './SkeletonLoading/ProductsSkeleton';
import SpringModal from '../../shared/Modal/Modal';
import CreateCategory from './CreateCategory/CreateCategory';
import ModalConfirmAction from './modalConfirmAction/ModalConfirmAction';

export default function Produtos() {
  const [categories, setCategories] = useState([]);
  const [categoriesCopy, setCategoriesCopy] = useState([]);

  const [categoryModal, setCategoryModal] = useState(false);
  const [mobileNavBar, setMobileNavBar] = useState(false);
  const [loadedCategories, setLoadedCategories] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isDeleteItem, setIsDeleteItem] = useState(false);
  const [isDeleteProduct, setIsDeleteProduct] = useState(false);

  const [infsCategory, setInfsCategory] = useState(null);
  const [stylesModalCategory, setStylesModalCategory] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [deleteWarningMessage, setDeleteWarningMessage] = useState('');
  const [overflow, setOverflow] = useState('auto');

  const [categoryToCreateProduct, setCategoryToCreateProduct] = useState(0);

  const {
    sidebar,
    setSidebar,
    setProductTempData,
    setProductData,
    setIdGroupComplement,
    setSaveImage,
  } = useFormProduct();
  const { width } = useWindowDimensions();

  //Refatoração
  const [productToEdit, setProductToEdit] = useState(null);

  useEffect(() => {
    getCategories();
    EventEmitter.on('hiddenVerticalScroll', (e) => handleOverflowSideBar(e));
    EventEmitter.on('update-products', (productInfs) =>
      handleUpdateCategoryProduct(productInfs)
    );
    return () => {
      setCategories([]);
      setCategoriesCopy([]);
      setCategoryModal(false);
      setInfsCategory(null);
      setMobileNavBar(false);
      EventEmitter.off('hiddenVerticalScroll');
      EventEmitter.off('update-products');
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = sidebar ? 'hidden' : 'auto';
    if (!sidebar) {
      setProductTempData(null);
      setProductData(null);
      setIdGroupComplement(0);
      setSaveImage(false);
    }
  }, [sidebar]);

  useEffect(() => {
    let widthSideBar = 0;
    if (width >= 1440) widthSideBar = '50%';
    else if (width >= 768 && width <= 1440) widthSideBar = '80%';
    else widthSideBar = '100%';
    setMobileNavBar(widthSideBar);

    if (width <= 500)
      setStylesModalCategory({ minWidth: '90%', maxWidth: '95%' });
    else setStylesModalCategory({ minWidth: '500px', maxWidth: '500px' });
  }, [width]);

  async function getCategories() {
    const response = await getAllCategories();
    if (!response || !response.success) {
      toast.error(
        'Ocorreu um erro ao buscar as categoria, tente novamente ou entre em contato conosco.'
      );
      return;
    }

    if (!response.results.length) {
      setLoadedCategories(true);
      toast.warning('Você não possui categorias criadas.');
      return;
    }

    getCategoryProducts(response.results);
  }

  async function getCategoryProducts(categories) {
    const response = await getAllProducts();
    if (!response || !response.success) {
      toast.error(
        'Ocorreu um erro ao buscar os produtos, tente novamente ou entre em contato conosco.'
      );
      return;
    }
    const configuredProducts = setProductsInfos(response.results);
    setCategoryProducts(categories, configuredProducts);
  }

  function setProductsInfos(products) {
    const storeFirebasePath = getItemInBrowserStorage(
      'CLIENT_DATA',
      false
    ).firebase_path;
    const configuredProducts = products.map((product) => {
      product.ativo = Boolean(product.ativo);
      product.invalid = false;
      product.preco = maskCurrencyBRL(product.preco);
      product.editing = false;
      product.image_url = `${process.env.REACT_APP_FIREBASE_URL}/estabelecimentos%2F${storeFirebasePath}%2Fprodutos%2F${product.imagem_local}?alt=media&token=${product.imagem_token}`;
      return product;
    });
    return configuredProducts;
  }

  function setCategoryProducts(categories, products) {
    const categoriesWithProducts = categories.map((category) => {
      category.produtos = products.filter(
        (item) => item.cod_categoria === category.codigo
      );
      category.produtos_filter = category.produtos;
      return category;
    });
    setCategories(categoriesWithProducts);
    setCategoriesCopy(categoriesWithProducts);
    setLoadedCategories(true);
  }

  function changeProductPrice(indexCategory, idProduct, value) {
    const allCategories = deepClone(categories);
    const category = allCategories[indexCategory];
    const indexProduct = category.produtos_filter.findIndex(
      (product) => product.codigo === idProduct
    );
    category.produtos_filter[indexProduct].preco =
      maskCurrencyBRLWithSymbolString(value);
    category.produtos_filter[indexProduct].editing = 'PRICE';
    category.produtos_filter[indexProduct].invalid = !Boolean(
      value.replace(/\D/g, '')
    );
    allCategories[indexCategory] = category;
    setCategories(allCategories);
  }

  function toggleProductStatus(indexCategory, idProduct) {
    const allCategories = deepClone(categories);
    const category = allCategories[indexCategory];
    const indexProduct = category.produtos_filter.findIndex(
      (product) => product.codigo === idProduct
    );
    category.produtos_filter[indexProduct].ativo =
      !category.produtos_filter[indexProduct].ativo;
    allCategories[indexCategory] = category;
    setCategories(allCategories);
    saveProductChange(category.produtos_filter[indexProduct], false);
  }

  async function saveProductChange(productData, priceChanged) {
    const productToSave = deepClone(productData);
    if (priceChanged)
      productToSave.preco = removeMaskPrice(productToSave.preco);
    const response = await updateProduct(productToSave);
    if (!response.success) {
      toast.error(
        `Ocorreu um erro ao atualizar o ${
          priceChanged ? 'valor' : 'status'
        } do produto.`
      );
      return;
    }
    toast.success(`${priceChanged ? 'Valor' : 'Status'} alterado com sucesso.`);
    if (priceChanged)
      resetProductEdited(productData.cod_categoria, productData.codigo);
  }

  function resetProductEdited(idCategory, idProduct) {
    const allCategories = deepClone(categories);
    const indexCategory = allCategories.findIndex(
      (category) => category.codigo === idCategory
    );
    const category = allCategories[indexCategory];
    const indexProduct = category.produtos_filter.findIndex(
      (product) => product.codigo === idProduct
    );
    category.produtos_filter[indexProduct].editing = null;
    allCategories[indexCategory] = category;
    setCategories(allCategories);
  }

  function searchProdut(productSearched) {
    const categories = categoriesCopy.map((category) => {
      category.produtos_filter = category.produtos.filter((product) =>
        product.nome.toLowerCase().includes(productSearched.toLowerCase())
      );
      return category;
    });
    setCategories(categories);
  }

  function openSideBarToCreateNewProduct(idCategoryToCreateProduct) {
    setCategoryToCreateProduct(idCategoryToCreateProduct);
    setProductToEdit(null);
    setProductData(null);
    setSidebar(true);
  }

  function editProduct(product) {
    setProductToEdit(product);
    setProductData(product);
    setSidebar(true);
  }

  //CREATE/EDIT PRODUCT
  function handleUpdateCategoryProduct(product) {
    if (product.new_product) {
      insertNewProduct(product);
      return;
    }
    getCategories();
  }

  function insertNewProduct(product) {
    const categorieToInsertProduct = categories.find(
      (category) => category.codigo === product.cod_categoria
    );
    if (!categorieToInsertProduct) {
      getCategories();
      return;
    }
    const productToInsert = setProductInfos(
      product,
      categorieToInsertProduct.nome
    );
    categorieToInsertProduct.produtos.push(productToInsert);
    updateCategoryProducts(
      categorieToInsertProduct.produtos,
      categorieToInsertProduct.codigo
    );
  }

  function setProductInfos(productToSetFields, categoryName) {
    const product = productToSetFields;
    product.editing = false;
    product.ifood_id = null;
    product.invalid = false;
    product.nome_categoria = categoryName;
    product.imagem_local = product.imagem_local ? product.imagem_local : null;
    product.imagem_token = product.imagem_token ? product.imagem_token : null;
    return product;
  }

  function updateCategoryProducts(products, idCategory) {
    const categoryIndex = categories.findIndex(
      (category) => category.codigo === idCategory
    );
    if (categoryIndex < 0) {
      toast.error(
        'Não foi possível incluir o produto a uma categoria, tente novamente ou entre em contato conosco.'
      );
      return;
    }
    const allCategories = deepClone(categories);
    allCategories[categoryIndex].produtos = products;
    allCategories[categoryIndex].produtos_filter = products;
    setCategories(allCategories);
    setCategoriesCopy(allCategories);
  }

  function editCategory(infsCategory) {
    setCategoryModal(true);
    setOpenModal(true);
    setInfsCategory(infsCategory);
  }

  function handleModal(isOpen) {
    setCategoryModal(isOpen);
    setOpenModal(isOpen);
    setIsDeleteItem(isOpen);
  }

  function updateCategories(data) {
    if (data.newCat) {
      let test = { ...data, produtos: [], produtos_filter: [] };
      categories.push(test);
      setTimeout(() => {
        setCategories(categories);
      }, 500);
    } else {
      let temp = categories.map((item) => {
        if (item.codigo === data.codigo) {
          item = { ...item, ...data };
        }
        return item;
      });
      setCategories(temp);
    }
  }

  function handleOverflowSideBar(hiddenContent) {
    if (hiddenContent) {
      setOverflow('hidden');
      return;
    }
    setOverflow('auto');
  }

  function confirmDelete(itemToDeleteDetails, isProduct = false) {
    setOpenModal(true);
    setIsDeleteItem(true);
    setCategoryModal(false);
    setItemToDelete(itemToDeleteDetails);
    setIsDeleteProduct(isProduct);

    if (!isProduct) {
      setDeleteWarningMessage(
        'Ao deletar está categoria todos os produtos presentes na mesma serão deletados também, deseja prosseguir ?'
      );
      return;
    }
    setDeleteWarningMessage(
      `Deletar o produto ${itemToDeleteDetails.nome}? Ao deletar este produto não será possível recuperá-lo, deseja prosseguir ?`
    );
  }

  async function handleDelete(confirmDelete) {
    if (!confirmDelete) {
      setIsDeleteItem(false);
      setCategoryModal(false);
      setOpenModal(false);
      return;
    }
    let response;
    if (isDeleteProduct) response = await deleteProduct(itemToDelete.codigo);
    else response = await deleteCategory(itemToDelete.codigo);

    if (!response || !response.success) {
      handleDelete(false);
      toast.error(
        `Ocorreu um erro ao deletar ${
          isDeleteProduct ? 'o produto' : 'a categoria'
        }, por favor tente novamente ou entre em contato conosco.`
      );
      return;
    }

    getCategories();
    handleDelete(false);
    toast.success(
      `${
        isDeleteProduct ? 'Produto deletado' : 'Categoria deletada'
      } com sucesso.`
    );
  }

  return (
    <div className="p-md-3 px-2 py-5 w-100 overflow-y-auto">
      <h3 className="text-primary fw-bold">Cardápio</h3>
      <h5 className="m-0 py-2 pt-1">
        Aqui você define quais produtos aparecerá no cardápio visualizado pelo
        cliente.
      </h5>

      <div className="d-flex flex-column py-2 gap-2 pb-3">
        <button
          className="btn btn-primary w-100 fw-bold p-2"
          style={{ maxWidth: 410 }}
          onClick={() => editCategory(null)}
        >
          <i className="fas fa-plus btnPlus"></i>
          <span>Criar nova categoria</span>
        </button>

        <div className="d-inline-flex w-100 gap-2 flex-wrap">
          <div className="dropdown-categories">
            <DropDown
              items={categoriesCopy}
              filterItems={setCategories}
              personalizeWidth={true}
            />
          </div>
          <input
            className="input-style w-100"
            style={{ maxWidth: 380 }}
            placeholder="Procurar produto"
            type="text"
            onChange={(e) => searchProdut(e.target.value)}
          />
        </div>
      </div>

      {!loadedCategories ? (
        <ProductsSkeleton />
      ) : categories.length ? (
        categories.map((category, categoryIndex) => {
          return (
            <div
              key={categoryIndex}
              className={`rounded border  p-2 text-black m-0 mb-2 ${
                category.ativo ? 'border-dark-subtle' : 'border-warning'
              }`}
            >
              <div className="align-items-center border-bottom d-flex fs-4 fw-bold justify-content-between  py-1">
                <div className="align-items-center d-inline-flex gap-2">
                  <button
                    className="d-flex btn btn-primary-fill p-0"
                    onClick={() => editCategory(category)}
                  >
                    <span className="material-symbols-outlined">edit</span>
                  </button>

                  <span className="border-2 border-start d-block px-2 w-100">
                    {category.nome}
                  </span>
                </div>
                <div className="align-items-center d-inline-flex gap-3">
                  <button
                    className="d-flex btn btn-primary-fill gap-2 p-0 text-primary"
                    onClick={() =>
                      openSideBarToCreateNewProduct(category.codigo)
                    }
                  >
                    <span className="material-symbols-outlined">add</span>
                  </button>

                  <button
                    className="d-flex btn btn-primary-fill p-0"
                    onClick={() => confirmDelete(category)}
                  >
                    <span className="material-symbols-outlined">delete</span>
                  </button>
                </div>
              </div>

              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className="labelColumn" align="left">
                        Produto
                      </TableCell>
                      <TableCell className="labelColumn" align="center">
                        Preço
                      </TableCell>
                      <TableCell className="labelColumn" align="center">
                        Status
                      </TableCell>
                      <TableCell
                        className="labelColumn"
                        align="center"
                      ></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {category.produtos_filter.length > 0 &&
                      category.produtos_filter.map((product, productIndex) => (
                        <TableRow key={productIndex}>
                          <TableCell align="left" padding="none">
                            <div className="d-flex align-items-center gap-2 py-3">
                              <div
                                className="align-icon"
                                onClick={() => editProduct(product)}
                              >
                                <span className="material-symbols-outlined">
                                  edit
                                </span>
                              </div>
                              {!product.imagem_local && (
                                <div className="CardProductImage"></div>
                              )}
                              {product.imagem_local && (
                                <img
                                  className="CardProductImage"
                                  src={product.image_url}
                                  alt="Imagem do produto"
                                />
                              )}
                              <div className="align-description">
                                <span className="text-name-product">
                                  {product.nome}
                                </span>
                                <span className="description-text">
                                  {product.descricao}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <div className="align-content">
                              <div
                                className={
                                  product.invalid
                                    ? 'border-price invalid-price'
                                    : 'border-price'
                                }
                              >
                                <input
                                  className="input-price"
                                  type="text"
                                  value={product.preco}
                                  onChange={(e) =>
                                    changeProductPrice(
                                      categoryIndex,
                                      product.codigo,
                                      e.target.value
                                    )
                                  }
                                />
                                {product.editing && !product.invalid && (
                                  <Tooltip title="Salvar alteração">
                                    <div
                                      className="align-icon-save"
                                      onClick={() =>
                                        saveProductChange(product, true)
                                      }
                                    >
                                      <span className="material-symbols-outlined">
                                        save
                                      </span>
                                    </div>
                                  </Tooltip>
                                )}
                              </div>
                              <span
                                className={
                                  product.invalid
                                    ? 'invalid-price-text'
                                    : 'hidden'
                                }
                              >
                                Preço inválido.
                              </span>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <div className="align-content">
                              <div
                                className="switch-btn"
                                onClick={() =>
                                  toggleProductStatus(
                                    categoryIndex,
                                    product.codigo
                                  )
                                }
                              >
                                <div
                                  style={{ paddingLeft: 11 }}
                                  className={
                                    product.ativo ? 'active' : 'disable'
                                  }
                                >
                                  Ativo
                                </div>
                                <div
                                  style={{ paddingRight: 5 }}
                                  className={
                                    !product.ativo ? 'active' : 'disable'
                                  }
                                >
                                  Pausar
                                </div>
                                <div
                                  className={
                                    product.ativo
                                      ? 'selected left'
                                      : 'selected right'
                                  }
                                ></div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <button
                              className="btn btn-primary-fill p-0"
                              onClick={() => confirmDelete(product, true)}
                            >
                              <span className="material-symbols-outlined">
                                delete
                              </span>
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          );
        })
      ) : (
        <div className="text-center py-3">
          <h3 className="text-danger fw-bold fs-4">
            Você não possui categorias
          </h3>
          <span className="fs-5 fs-6 fw-bold">
            Crie uma para começar a cadastrar produtos nela.
          </span>
        </div>
      )}
      {sidebar && (
        <Sidebar
          sidebar={
            <CreateProduct
              product={productToEdit}
              categories={categoriesCopy}
              categoryToCreateProduct={categoryToCreateProduct}
            />
          }
          open={sidebar}
          onSetOpen={setSidebar}
          pullRight={true}
          styles={{
            sidebar: {
              background: 'white',
              width: mobileNavBar,
              zIndex: 999,
              maxWidth: 800,
              overflow: overflow,
            },
          }}
        >
          <div />
        </Sidebar>
      )}
      {
        <SpringModal
          handleOpen={openModal}
          handleClose={handleModal}
          customStyleModal={stylesModalCategory}
        >
          {categoryModal && (
            <CreateCategory
              dataCategory={infsCategory}
              returnCategory={updateCategories}
            />
          )}
          {!categoryModal && isDeleteItem && (
            <ModalConfirmAction
              title={`Excluir ${isDeleteProduct ? 'produto' : 'categoria'}`}
              warningMesage={deleteWarningMessage}
              labelConfirmBtn={'Prosseguir'}
              handleAction={handleDelete}
            ></ModalConfirmAction>
          )}
        </SpringModal>
      }
    </div>
  );
}
