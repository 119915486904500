import React, { useState, useEffect, useRef } from 'react';
import './Header.scss';

import DeliveryLogo from '../../assets/Images/logo/logo.png';

import HamburgerIcon from '../../shared/HamburgerIcon/HamburgerIcon';
import Sidebar from 'react-sidebar';
import { useWindowDimensions } from '../../Utils/Index';

function Header() {
  const [openHeaderSideBar, setOpenHeaderSideBar] = useState(false);
  const [useMobileLayout, setUseMobileLayout] = useState(false);
  const [useFixedHeader, setUseFixedHeader] = useState(false);

  const [headerOptions, setHeaderOptions] = useState([
    {
      label: 'Início',
      active: true,
      ref: '#start',
      highlight: false,
    },
    {
      label: 'Planos',
      active: false,
      ref: '#plans',
      highlight: false,
    },
    {
      label: 'Como funciona',
      active: false,
      ref: '#howWork',
      highlight: false,
    },
    {
      label: 'Quem somos',
      active: false,
      ref: '#about',
      highlight: false,
    },
    {
      label: 'Contato',
      active: false,
      ref: '#contacts',
      highlight: false,
    },
    {
      label: 'Começar a usar',
      active: false,
      ref: '/login',
      highlight: true,
    },
  ]);

  const { width } = useWindowDimensions();

  const headerRef = useRef();

  useEffect(() => {
    setUseMobileLayout(width <= 1080);
  }, [width]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setUseFixedHeader(window.scrollY > 80);
  };

  function handleActiveSection(sectionData) {
    const updateActiveSection = headerOptions.map((option) => {
      option.active = option.ref === sectionData.ref;
      return option;
    });
    if (useMobileLayout) {
      setOpenHeaderSideBar(false);
      handleVisibilitySideBar(false);
    }
    setHeaderOptions(updateActiveSection);
  }

  function handleVisibilitySideBar(statusVisibilitySideBar) {
    const divSideBar = headerRef.current.querySelector('[role="navigation"]');
    if (divSideBar) {
      divSideBar.style.height = '100vh';
      divSideBar.classList.add(
        `${
          statusVisibilitySideBar
            ? 'mobile-sidebar-open'
            : 'mobile-sidebar-close'
        }`
      );
      divSideBar.classList.remove(
        `${
          statusVisibilitySideBar
            ? 'mobile-sidebar-close'
            : 'mobile-sidebar-open'
        }`
      );
      setOpenHeaderSideBar(statusVisibilitySideBar);
    }
  }

  return (
    <div
      className={useFixedHeader ? 'header fixed-header' : 'header'}
      ref={headerRef}
    >
      <div className="header-container">
        <div className="header__left-section">
          <img
            src={DeliveryLogo}
            className="header__left-section-logo"
            alt="Logotipo Delivery.legal"
          />
        </div>
        <div className="header__right-section">
          {!useMobileLayout ? (
            <div className="header__right-section__header-desktop">
              {headerOptions.map((headerOption, index) => {
                return (
                  <a
                    key={index}
                    onClick={() => handleActiveSection(headerOption)}
                    href={headerOption.ref}
                    className={`header__right-section__header-desktop-option
                        ${headerOption.active ? 'current-section' : ''}
                        ${
                          headerOption.highlight
                            ? 'header__right-section__header-desktop-highlight'
                            : ''
                        }

                        `}
                  >
                    {headerOption.label}
                  </a>
                );
              })}
            </div>
          ) : (
            <div className="header__right-section__header-mobile">
              <div
                className="header__right-section__header-mobile-hamburguer-icon"
                onClick={() => handleVisibilitySideBar(!openHeaderSideBar)}
              >
                <HamburgerIcon
                  initialValue={openHeaderSideBar}
                  customClass="hamburguer-button__content"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {useMobileLayout && (
        <Sidebar
          sidebar={
            <div className="header__right-section__header-mobile__sidebar">
              <div className="header__right-section__header-mobile__sidebar-nav">
                {headerOptions.map((headerOption, index) => {
                  return (
                    <a
                      key={index}
                      onClick={() => handleActiveSection(headerOption)}
                      href={headerOption.ref}
                      className={`header__right-section__header-desktop-option
                        ${headerOption.active ? 'current-section' : ''}
                        ${
                          headerOption.highlight
                            ? 'header__right-section__header-desktop-highlight'
                            : ''
                        }
                            `}
                      style={{ padding: 8 }}
                    >
                      {headerOption.label}
                    </a>
                  );
                })}
              </div>
            </div>
          }
          open={openHeaderSideBar}
          onSetOpen={handleVisibilitySideBar}
          pullRight={false}
          styles={{
            sidebar: {
              background: 'white',
              width: 250,
              zIndex: 3,
              maxWidth: 800,
            },
          }}
          className={`header__right-section__header-mobile${
            openHeaderSideBar ? '-open' : '-close'
          }`}
        >
          <></>
        </Sidebar>
      )}
    </div>
  );
}

export default Header;
