import React from 'react';
import './Footer.scss';

import SmallDeliveryLogo from '../../assets/Images/logo/small_logo.png';

import { IconHeart } from '../../assets/Icons';

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer__content">
          <img
            src={SmallDeliveryLogo}
            className="footer__content-image"
            alt="Icone do Delivery.legal"
          />
          <div className="footer__content__topics">
            <div>
              <h3 className="footer__content__topics-title">Site</h3>
              <ul className="footer__content__topics-list">
                <li className="footer__content__topics-item">
                  <a href="#plans">planos</a>
                </li>
                <li className="footer__content__topics-item">
                  <a href="#howWork">como funciona</a>
                </li>
                <li className="footer__content__topics-item">
                  <a href="#about">quem somos</a>
                </li>
                <li className="footer__content__topics-item">
                  <a href="#contacts">contato</a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="footer__content__topics-title">Comercial</h3>
              <ul className="footer__content__topics-list">
                <li className="footer__content__topics-item">
                  <a href="/login">ativação do estabelecimento</a>
                </li>
                {/* <li className="footer__content__topics-item"><a href="#">falar com representante</a></li> */}
              </ul>
            </div>
            <div>
              <h3 className="footer__content__topics-title">Suporte</h3>
              <ul className="footer__content__topics-list">
                <li className="footer__content__topics-item">
                  <span>em breve</span>
                </li>
                {/* <li className="footer__content__topics-item"><span>manual de uso</span></li>
                                <li className="footer__content__topics-item"><span>dúvidas frequentes</span></li>
                                <li className="footer__content__topics-item"><span>abertura de ticket</span></li> */}
              </ul>
            </div>
          </div>
        </div>
        <span className="footer-message">
          Feito com&nbsp;
          <IconHeart />
          &nbsp;em São Paulo
        </span>
      </div>
    </div>
  );
}
