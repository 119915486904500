import React from 'react';
import './LandingPage.scss';

import Slider from 'react-slick';

import Header from './Header/Header';
import Banner from './Banner/Banner';
import Plans from './Plans/Plans';
import HowItWorks from './HowItWorks/HowItWorks';
import AboutUs from './AboutUs/AboutUs';
import ContactUs from './ContactUs/ContactUs';
import Footer from './Footer/Footer';

export default function LandingPage() {
  const lpImage1 = `${process.env.REACT_APP_FIREBASE_URL}/assets%2Flanding_page%2Flanding_page_banner1.png?alt=media&token=3238e44b-c810-46b4-be4d-44f0df22a4e9`;
  const lpImage2 = `${process.env.REACT_APP_FIREBASE_URL}/assets%2Flanding_page%2Flanding_page_banner2.png?alt=media&token=7daa3a04-d7ed-4d87-adcc-093130e931b4`;

  const itemsSlider = [lpImage1, lpImage2];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function handleToSection() {
    window.document
      .getElementById('contacts')
      .scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div style={{ zIndex: 3 }}>
      <div id="start">
        <Header />
      </div>
      <Banner />
      <div className="landing-page-slider">
        <Slider {...settings} style={{ cursor: 'pointer' }}>
          {itemsSlider.map((image, index) => {
            return (
              <img
                onClick={() => handleToSection()}
                key={index}
                src={image}
                width="100%"
                alt=""
              />
            );
          })}
        </Slider>
      </div>
      <div id="plans">
        <Plans />
      </div>
      <div id="howWork">
        <HowItWorks />
      </div>
      <div id="about">
        <AboutUs />
      </div>
      <div id="contacts">
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
}
