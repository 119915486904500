import { Skeleton } from '@material-ui/lab';
import React from 'react';
import '../Pedidos.scss';

export default function TextHeader({ text = true, ifood = false }) {
  //Loader para textos e icones com texto
  return (
    <div className="data">
      {text ? (
        <Skeleton
          className="input-style"
          animation="wave"
          variant="text"
          width={80}
          height={30}
          style={{ borderRadius: 8, border: 'none', bottom: 10, left: 0 }}
        />
      ) : (
        <div className="data data-icon">
          <Skeleton
            className="input-style"
            animation="wave"
            variant="rect"
            width={30}
            height={30}
            style={{
              borderRadius: 8,
              border: 'none',
              margin: 0,
              padding: 0,
              bottom: ifood ? -15 : 5,
              marginRight: 8,
            }}
          />
          <Skeleton
            className="input-style"
            animation="wave"
            variant="text"
            width={60}
            height={30}
            style={{
              borderRadius: 8,
              border: 'none',
              margin: 0,
              padding: 0,
              bottom: ifood ? -15 : 5,
            }}
          />
        </div>
      )}
    </div>
  );
}
