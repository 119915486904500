import React, { useEffect, useState } from 'react';
import defaultProductImage from '../../assets/Images/no-product-image.png';

function ProductCheckout({ product, removeProduct }) {
  const [image, setImage] = useState(defaultProductImage);

  useEffect(() => {
    if (product.image) setImage(product.image);
  }, [product]);

  return (
    <div className="hstack py-2 border-bottom product-container gap-1">
      <div className="hstack gap-1">
        <button
          onClick={() => removeProduct()}
          className="btn btn-primary-fill p-0"
        >
          <span className="material-symbols-outlined fs-6">delete</span>
        </button>

        <img
          src={image}
          alt="Imagem do produto"
          className="rounded"
          width={100}
          onError={() => setImage(defaultProductImage)}
        />
      </div>

      <div className="w-100">
        <div className="vstack mb-2">
          <div className="hstack gap-1">
            <span className="fs-8 fw-bold">{product.qtd}x</span>
            <span>{product.name}</span>
          </div>

          <div className="hstack w-100 justify-content-between fs-8">
            <span>Valor unitário:</span>
            <span>R${product.price.formated}</span>
          </div>
        </div>

        {product.complementos.length > 0 && (
          <ul className="list-group list-group-flush">
            {product.complementos.map((complement) => {
              return (
                <li
                  className="list-group-item fs-8 p-0 hstack gap-1 justify-content-between"
                  key={complement.cod_complemento}
                >
                  <div className="hstack gap-1">
                    <span className="fw-bold">{complement.qtd}x</span>
                    <span>{complement.name}</span>
                  </div>

                  <span>{complement.price.formated}</span>
                </li>
              );
            })}
          </ul>
        )}

        <div className="hstack w-100 justify-content-between fs-7 mt-2">
          <span>Subtotal:</span>
          <span className="fw-bold text-success">R${product.sub_total}</span>
        </div>
      </div>
    </div>
  );
}

export default ProductCheckout;
