import React from 'react';
import { IconError } from '../../../assets/Icons';

function WithoutPlan({ availableDays, goToPlansPage }) {
  return (
    <div className="without-plan-container">
      <div className="warning-not-subscription">
        <IconError color={'#F10542'} stroke={'#3E3E3E'} opacity={0.9} />
        Notamos que sua assinatura não está ativa!
      </div>

      <div className="box-total">
        {availableDays > 0 && (
          <div className="box-day-subscribe">
            <span className="box-day-subscribe-plan">Plano trial</span>
            <div className="box-day-subscribe-day">{availableDays}</div>
            <div className="box-day-subscribe-restant">dia(s) restantes</div>
          </div>
        )}
      </div>

      <div className="store-login__fields-section__buttons">
        <button
          type="submit"
          onClick={() => goToPlansPage(true)}
          style={{ width: '100%' }}
        >
          Contratar um plano
        </button>
      </div>
    </div>
  );
}

export default WithoutPlan;
