import React, { useState, useEffect, useRef, useContext } from 'react';
import './SendProposal.scss';

import * as Yup from 'yup';
import { Form } from '@unform/web';

import Input from '../../shared/Input/Input';
import { sendBankSlip } from '../../services/SalesRepresentativeService';
import {
  copyText,
  getItemInBrowserStorage,
  saveInBrowserStorage,
  validCNPJ,
  validCpf,
} from '../../Utils/Index';
import { toast } from 'react-toastify';
import loadingGif from '../../assets/loading.gif';
import {
  IconCheckAnimated,
  IconDone,
  IconDownload,
  IconEyeHide,
  IconEyeShow,
  IconLogout,
} from '../../assets/Icons';
import { SalesRepresentative } from '../../Authentication/Provider/AuthenticationProvider';

function SendProposal() {
  const [generatingTicket, setGeneratingTicket] = useState(false);
  const [generatedTicket, setGeneratedTicket] = useState(false);
  const [showFeedbackTicker, setShowFeedbackTicker] = useState(false);
  const [clearField, setClearField] = useState(false);
  const [isLastTicketEmitted, setIsLastTicketEmitted] = useState(false);
  const [showBarCode, setShowBarCode] = useState(false);

  const [ticketLink, setTicketLink] = useState(null);
  const [barCode, setBarCode] = useState(null);

  const { representativeData, setRepresentativeData } =
    useContext(SalesRepresentative);

  const formRef = useRef(null);

  useEffect(() => {
    if (!representativeData) {
      getRepresentativeDataInStorage();
      return;
    }
  }, []);

  useEffect(() => {
    const lastTicketEmitted = getItemInBrowserStorage('LAST_EMITED_TICKER');
    if (lastTicketEmitted) handleTicketData(lastTicketEmitted, true);
  }, []);

  function getRepresentativeDataInStorage() {
    const personalData = getItemInBrowserStorage('CLIENT_DATA', false);
    if (!personalData) {
      toast.error(
        'Ocorreu um erro ao validar os dados da sessão, por favor realize o login novamente.'
      );
      setTimeout(() => {
        window.open('/login', '_self');
      }, 3000);
      return;
    }
    setRepresentativeData(personalData);
  }

  async function handleFormData(data) {
    try {
      const dataToSendoProposital = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        cnpj_cpf: Yup.string()
          .required('Informe ao menos um documento')
          .verifyDocument('Documento inválido'),
        phone_number: Yup.string()
          .required('Telefone é obrigatório')
          .matches(/[0-9]{2}\s[0-9]{5}-[0-9]{4}/, 'Número inválido'),
        phone_ddd: Yup.string(),
        email: Yup.string()
          .required('Email é obrigatório')
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            'Email inválido'
          ),
        cod_representante: Yup.string(),
      });

      const schema = dataToSendoProposital;
      await schema.validate(data, { abortEarly: false });
      formRef.current.setErrors({});
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  Yup.addMethod(Yup.string, 'verifyDocument', function (errorMessage) {
    return this.test(`verify-document`, errorMessage, function (document) {
      if (validCNPJ(document)) return true;
      if (validCpf(document)) return true;
      return false;
    });
  });

  async function handleSubmit(formData) {
    const formIsValid = await handleFormData(formData);

    if (!formIsValid) {
      toast.error('Preencha todos os dados do formulário corretamente.');
      return;
    }

    const dataToSendTicket = formRef.current.getData();
    dataToSendTicket.cnpj_cpf = dataToSendTicket.cnpj_cpf.replace(/\D/g, '');
    dataToSendTicket.phone_ddd = dataToSendTicket.phone_number.substring(0, 2);
    dataToSendTicket.phone_number = dataToSendTicket.phone_number
      .replace(/\D/g, '')
      .substring(2, 11);
    dataToSendTicket.cod_representante = representativeData.cod_usuario;
    sendTicket(dataToSendTicket);
  }

  function clearFormFields() {
    formRef.current.reset();
    setClearField(true);
    setTimeout(() => {
      setClearField(false);
    }, 1000);
  }

  async function sendTicket(dataToGenerateTicket) {
    setGeneratingTicket(true);
    setGeneratedTicket(false);
    setShowFeedbackTicker(true);

    const response = await sendBankSlip(dataToGenerateTicket);

    if (!response || !response.success) {
      setGeneratingTicket(false);
      setGeneratedTicket(false);
      toast.error(
        'Ocorreu um erro ao gerar boleto, tente novamente ou entre em contato conosco.'
      );
      return;
    }

    clearFormFields();
    setGeneratingTicket(false);
    setGeneratedTicket(true);
    setTimeout(() => {
      setShowFeedbackTicker(false);
    }, 2500);

    handleTicketData(response.result.boletos[0], false);
  }

  function handleTicketData(ticketDetails, isLastEmitted = false) {
    setIsLastTicketEmitted(isLastEmitted);

    if (isLastEmitted) setGeneratedTicket(isLastEmitted);

    const { barcode, paymentLink } = ticketDetails;

    if (!paymentLink)
      toast.error('Ocorreu um erro ao gerar o código do boleto.');
    else setTicketLink(paymentLink);

    if (!barcode)
      toast.error('Ocorreu um erro ao gerar o link para acesso do boleto.');
    else setBarCode(barcode);

    saveInBrowserStorage('LAST_EMITED_TICKER', ticketDetails);
  }

  function handlerActionTicker(action) {
    switch (action) {
      case 'open':
        window.open(ticketLink, '_blank');
        break;
      case 'copy':
        copyText(barCode);
        toast.success('Código de barras copiado com sucesso.');
        break;
      default:
        break;
    }
  }

  function handleVisibilityBarCode() {
    setShowBarCode(!showBarCode);
  }

  return (
    <div className="send-ticket">
      <h1 className="sales-representative-section-header">Enviar proposta</h1>
      <div className="send-ticket__content">
        <div className="send-ticket-form">
          <Form ref={formRef} onSubmit={handleSubmit} style={{ width: '80%' }}>
            <Input
              name="name"
              type="text"
              className="sign-in__input mb"
              label="Nome"
              containerStyle={{ marginRight: 32 }}
              maxLength={70}
            />

            <Input
              name="email"
              type="email"
              className="sign-in__input mb"
              label="Email"
              maxLength={50}
            />

            <Input
              name="cnpj_cpf"
              type="cnpj_cpf"
              className="sign-in__input mb"
              label="CPF/CNPJ"
              maxLength={18}
              mask={'CPF'}
              cpfAndCnpj={true}
              clearValueInput={clearField}
            />

            <Input
              name="phone_number"
              type="phone_number"
              className="sign-in__input mb"
              label="Telefone"
              maxLength={13}
              mask={'PHONE'}
              clearValueInput={clearField}
            />
            <div
              className="sign-in__container-sign-btn align-btns-register"
              style={{ minWidth: 'none' }}
            >
              <button
                type="button"
                onClick={() => clearFormFields()}
                className="sign-in__container-sign-btn-back"
              >
                Limpar formulário
              </button>
              <button
                type="submit"
                className="sign-in__container-sign-btn-confirm"
              >
                Gerar boleto
              </button>
            </div>
          </Form>
        </div>
        {generatedTicket ? (
          <div className="send-ticket__options">
            <div className="send-ticket__options__feedback">
              {!isLastTicketEmitted && <IconDone color="#02B345" />}
              <span className="send-ticket__options__feedback-label">
                {isLastTicketEmitted
                  ? 'Dados do último boleto gerado'
                  : 'Boleto gerado com sucesso!'}
              </span>
            </div>
            <span className="send-ticket__options-label">
              Como deseja prosseguir?
            </span>
            <div className="send-ticket__options__group-bts">
              {ticketLink && (
                <button
                  className="send-ticket__options__group-bts-btn"
                  onClick={() => handlerActionTicker('open')}
                >
                  <IconDownload size={20} color="#F10542" />
                  <span className="send-ticket__options__group-bts-btn-label">
                    Baixar boleto
                  </span>
                </button>
              )}
              {barCode && (
                <button
                  className="send-ticket__options__group-bts-btn"
                  onClick={() => handlerActionTicker('copy')}
                >
                  <IconLogout size={20} color="#F10542" />
                  <span className="send-ticket__options__group-bts-btn-label">
                    Copiar código de barras
                  </span>
                </button>
              )}
              {
                <div className="send-ticket__options__details">
                  <div
                    className="send-ticket__options__details__icon-container"
                    onClick={() => handleVisibilityBarCode()}
                  >
                    <div>
                      {showBarCode ? (
                        <IconEyeShow size={20} color="#F10542" />
                      ) : (
                        <IconEyeHide size={20} color="#F10542" />
                      )}
                    </div>
                    <span
                      className="send-ticket__options__details-label"
                      onClick={() => handleVisibilityBarCode()}
                    >
                      Visualizar dados do boleto
                    </span>
                  </div>
                  {showBarCode && (
                    <div className="send-ticket__options__details-datas">
                      {barCode && (
                        <span className="send-ticket__options__details-barcode">
                          Código de barras: {barCode}
                        </span>
                      )}
                      {ticketLink && (
                        <span className="send-ticket__options__details-barcode">
                          Link do boleto:{' '}
                          <a
                            className="send-ticket__options__details-link"
                            href={ticketLink}
                            target="blank"
                          >
                            Link
                          </a>
                        </span>
                      )}
                    </div>
                  )}
                </div>
              }
              {/* <button className="send-ticket__options__group-bts-btn"> <IconMessage size={20} color="#F10542"/> <span className="send-ticket__options__group-bts-btn-label">Enviar por e-mail</span></button> */}
            </div>
          </div>
        ) : (
          <div className="send-ticket__options">
            <div className="send-ticket__options__feedback">
              <span className="send-ticket__options__feedback-label">
                Não há boleto gerado.
              </span>
            </div>
          </div>
        )}
      </div>

      {showFeedbackTicker && generatingTicket && (
        <div className="ticket-loading">
          <div className="ticket-loading__top-section">
            <h4 className="ticket-loading__top-section-label">
              Por favor aguarde, estamos trabalhando para gerar seu boleto.
            </h4>
            <img
              className="ticket-loading__top-section-icon"
              src={loadingGif}
              alt="Icone de conteúdo carregando"
              width={50}
            />
          </div>
        </div>
      )}
      {showFeedbackTicker && !generatingTicket && generatedTicket && (
        <div className="ticket-loading">
          <div className="ticket-loading__top-section">
            <h4 className="ticket-loading__top-section-label">
              Boleto gerado com sucesso!
            </h4>
            <div className="ticket-loading__top-section-icon">
              <IconCheckAnimated />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SendProposal;
