import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { setConfigAll } from '../services/Configuration';
import DeliveryLogo from '../assets/Images/logo/logo.png';
import Geral from '../painel/Configuracao/components/Geral';
import HorarioLoja from '../painel/Configuracao/components/HorarioLoja';
import Endereco from '../painel/Configuracao/components/Endereco';
import TempoEstimado from '../painel/Configuracao/components/TempoEstimado';
import TaxaDelivery from '../painel/Configuracao/components/TaxaDelivery';
import PaymentsAccept from '../painel/Configuracao/components/PaymentsAccept/PaymentsAccept';
import ConfigurationSteps from './components/ConfigurationSteps';
import {
  getItemInBrowserStorage,
  saveInBrowserStorage,
  validCpf,
} from '../Utils/Index';
import { jwtDecode } from 'jwt-decode';
import { StoreOwner } from '../Authentication/Provider/AuthenticationProvider';

function ConfigureStore() {
  const components = [
    <Geral
      firstAccess={true}
      handleConfigureStore={() => handleStepConclued(1)}
    />,
    <HorarioLoja
      firstAccess={true}
      handleConfigureStore={() => handleStepConclued(2)}
    />,
    <Endereco
      firstAccess={true}
      handleConfigureStore={() => handleStepConclued(3)}
    />,
    <TempoEstimado
      firstAccess={true}
      handleConfigureStore={() => handleStepConclued(4)}
    />,
    <TaxaDelivery
      firstAccess={true}
      handleConfigureStore={() => handleStepConclued(5)}
    />,
    <PaymentsAccept
      firstAccess={true}
      handleConfigureStore={() => handleStepConclued(6)}
    />,
  ];

  const [component, setComponent] = useState(components[0]);
  const [configuringStore, setConfiguringStore] = useState(true);
  const [step, setStep] = useState(0);

  const { storeOwnerData } = useContext(StoreOwner);

  useEffect(() => {
    verifyHasStoreInfos();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  async function verifyHasStoreInfos() {
    const sessionInfos = getItemInBrowserStorage('STORE_INITIAL_SETTINGS');
    if (sessionInfos !== null) return;
    const jwtData = jwtDecode(storeOwnerData.token);
    await saveInBrowserStorage('STORE_INITIAL_SETTINGS', {
      id_projeto: jwtData.id_projeto,
      uuid_estabelecimento: jwtData.uuid_estabelecimento,
      id_estabelecimento: storeOwnerData.cod_estabelecimento,
      nome: storeOwnerData.nome_estabelecimento,
      email: storeOwnerData.email,
      telefone: storeOwnerData.telefone,
      documento: storeOwnerData.documento,
      documento_tipo: validCpf(storeOwnerData.documento) ? 'CPF' : 'CNPJ',
      uuid_revendedor: null,
    });
  }

  function handleStepConclued(newStep) {
    if (newStep === 6) {
      callSetStoreIsConfigurated();
      return;
    }
    setStep(newStep);
    setComponent(components[newStep]);
  }

  async function callSetStoreIsConfigurated() {
    const response = await setConfigAll(true);
    if (!response || !response.success) {
      toast.error(
        'Ocorreu um erro ao salvar as configurações do estabelecimento, tente novamente ou entre em contato conosco.'
      );
      return;
    }
    setConfiguringStore(false);
  }

  return (
    <>
      {configuringStore ? (
        <div className="d-flex flex-column w-100">
          <div className="d-flex flex-column w-100 px-2 pt-2">
            <img
              src={DeliveryLogo}
              width="130px"
              alt="Logotipo Delivery Legal"
            />

            <h1 className="fw-bold fs-3 w-100 d-block text-center m-0">
              Seja bem-vindo
            </h1>

            <span className="fs-8 text-center text-secondary">
              Antes de começar a realizar as vendas precisamos que realize
              algumas configurações em seu estabelecimento.
            </span>

            <div className="w-100 hstack justify-content-center mt-2">
              <ConfigurationSteps currentStepIndex={step} />
            </div>
          </div>

          <div className="w-100 p-2"> {component} </div>
        </div>
      ) : (
        <div className="d-flex flex-column w-100 vh-100 p-2 gap-2 justify-content-center">
          <img src={DeliveryLogo} width="130px" alt="Logotipo Delivery Legal" />

          <h1 className="fw-bold fs-3 w-100 d-block text-center m-0 text-success">
            Configurações salvas
          </h1>

          <span className="fs-7 text-center">
            Você será redicionado para a página do cardápio do estabelecimento,
            nela poderá criar categorias e produtos.
          </span>

          <span className="fs-7 text-center">
            Caso queira alterar alguma configuração, basta clicar na sessão
            "Configurações" no menu lateral.
          </span>

          <button
            className="btn btn-primary fw-bold"
            onClick={() => window.open('painel/cardapio', '_self')}
          >
            Acessar cardápio
          </button>
        </div>
      )}
    </>
  );
}

export default ConfigureStore;
