import React, { useEffect, useReducer } from 'react';
import { uploadStoreLogo } from '../../../firebase/storage';
import { changeColor, uploadPhotoLogo } from '../../../services/Products';
import { copyText, getItemInBrowserStorage } from '../../../Utils/Index';
import {
  saveLinkStore,
  verifyLinkStore,
  getGeral,
} from '../../../services/Configuration';
import { toast } from 'react-toastify';
import { Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const StyledTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'var(--color-primary)',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'var(--color-primary)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E4001Bred',
      },
      '&:hover fieldset': {
        borderColor: 'var(--color-primary)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--color-primary)',
      },
    },
  },
})(TextField);

export default function Geral({ firstAccess = false, handleConfigureStore }) {
  const [store, updateStore] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      link: '',
      newLink: null,
      updateLink: false,
      color: '',
      newColor: null,
      updateColor: false,
      logo: '',
      newLogo: null,
      updateLogo: true, //temporary
    }
  );

  useEffect(() => {
    if (!firstAccess) callGetStoreInfo();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (store.updateLink && store.updateColor && store.updateLogo)
      handleConfigureStore(true);
  }, [store.updateLink, store.updateColor, store.updateLogo]); //eslint-disable-line react-hooks/exhaustive-deps

  async function callGetStoreInfo() {
    getGeral()
      .then((response) => {
        const { results } = response;
        updateStore({
          link: results.link,
          color: results.cor_primaria,
          logo: buildStoreLogo(results.imagem_local, results.imagem_token),
        });
      })
      .catch((error) => {
        console.error('STORE_INFOS', error);
        toast.error(
          'Ocorreu um erro ao buscar as informações do estabelecimento, tente novamente ou entre em contato conosco.'
        );
      });
  }

  function buildStoreLogo(path, token) {
    if (!path || !token) return '';
    const store = getItemInBrowserStorage('CLIENT_DATA', false);
    return `${process.env.REACT_APP_FIREBASE_URL}/estabelecimentos%2F${store.firebase_path}%2Flogo%2F${path}?alt=media&token=${token}`;
  }

  // LINK
  function handleStoreLink() {
    const response = verifyStoreLink();
    if (!response.valid) {
      toast.error(response.error);
      return;
    }
    callSaveStoreLink();
  }

  function verifyStoreLink() {
    if (store.newLink.length < 3)
      return {
        valid: false,
        error: 'Link de acesso precisa conter ao menos 3 caracteres',
      };

    const onlyNumbersAndLetters = /^[a-zA-Z0-9]+$/;
    if (!onlyNumbersAndLetters.test(store.newLink))
      return {
        valid: false,
        error:
          'Link de acesso inválido! Não pode conter caracteres especiais e espaços',
      };

    return { valid: true, error: '' };
  }

  async function callSaveStoreLink() {
    const linkValidation = await verifyLinkStore(store.newLink);
    if (!linkValidation || !linkValidation.success) {
      toast.error(
        'Ocorreu um erro ao validar o link de acesso, tente novamente ou entre em contato com o nosso suporte.'
      );
      return;
    }

    if (linkValidation.results.existente) {
      toast.warn('Ops! Este link de acesso não está disponível, tente outro.');
      return;
    }

    const response = await saveLinkStore(store.newLink);
    if (!response || !response.success) {
      toast.error(
        'Ocorreu um erro ao salvar o novo link de acesso, tente novamente ou entre em contato com o nosso suporte.'
      );
      return;
    }
    updateStore({ link: store.newLink, newLink: '', updateLink: true });
    toast.success('Link de acesso salvo!');
  }

  // LOGO
  function selectStoreImage(image) {
    if (!image) {
      toast.warn('Por favor selecione uma imagem');
      return;
    }

    if (image.size > 5242880) {
      toast.error(
        'Ops! O tamanho da imagem é muito grande, a imagem deve ser de até 5MB.'
      );
      return;
    }

    if (!['image/png', 'image/jpeg', 'image/jpg'].includes(image.type)) {
      toast.error('Arquivo inválido, por favor selecione outro');
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      updateStore({ newLogo: reader.result });
    };
    if (image) reader.readAsDataURL(image);
  }

  async function handleLogo(file) {
    const uploadImageResponse = await uploadStoreLogo(file);
    if (!uploadImageResponse.success) {
      toast.warn(uploadImageResponse.msg, { autoClose: 6000 });
      return;
    }

    const response = await uploadPhotoLogo(
      'logo',
      uploadImageResponse.imagem_local,
      uploadImageResponse.imagem_token
    );
    if (!response || !response.success) {
      toast.error(
        'Ocorreu um erro ao salvar a logo, por favor tente novamente'
      );
      return;
    }

    toast.success('Logo salva com sucesso');
    updateStore({ logo: store.newLogo, newLogo: '', updateLogo: true });
  }

  // COLOR
  function setStoreColorHex(color, isWritten = false) {
    if (isWritten) {
      if (!color) {
        updateStore({ newColor: '' });
        return;
      }
      let value = color.replace(/[^a-zA-Z0-9 ]/g, '');
      value = `#${value}`;
      updateStore({
        newColor: value,
      });
      return;
    }
    updateStore({ newColor: color });
  }

  function copyStoreLink() {
    const response = copyText(`delivery.legal/cardapio/${store.link}`);
    if (!response) {
      toast.error(
        'Ocorreu um erro ao copiar o link de acesso, por favor tente novamente ou entre em contato conosco.'
      );
      return;
    }
    toast.success('link de acesso copiado!');
  }

  async function handleStoreColor() {
    const response = await changeColor(store.color);
    if (!response || !response.success) {
      toast.error(
        'Ocorreu um erro ao salvar a cor do estabelecimento, tente novamente ou entre em contato conosco.'
      );
      return;
    }
    updateStore({ color: store.newColor, newColor: '', updateColor: true });
    toast.success('Cor salva com sucesso');
  }

  function handleStoreInfos() {
    if (firstAccess && (!store.newLogo || !store.newLink || !store.newColor)) {
      toast.warn('Preencha todas as informações');
      return;
    }
    if (!store.newLogo && !store.newLink && !store.newColor) return;
    if (store.newLogo) handleLogo();
    if (store.newLink) handleStoreLink();
    if (store.newColor) handleStoreColor();
  }

  return (
    <div className="w-100 h-100" style={{ maxWidth: 550 }}>
      <div className="vstack">
        <div className="vstack pb-2">
          <h3 className="fs-3 fw-bold m-0 p-0 text-primary">Logotipo</h3>
          <span className="fs-7 text-body-secondary">
            Adicione o logotipo do seu estabelecimento
          </span>
        </div>

        <div className="position-relative d-flex flex-column align-items-center justifu-content-center">
          <label
            htmlFor="upload-store-logo"
            className="border border-1 border-secondary overflow-hidden rounded-3"
          >
            {store.logo || store.newLogo ? (
              <img
                src={store.newLogo ? store.newLogo : store.logo}
                width={'120px'}
                height={'120px'}
                alt="Preview da imagem da logo selecionada"
              />
            ) : null}
          </label>

          <label
            htmlFor="upload-store-logo"
            className="border border-primary mt-2 py-2 rounded-1 text-center w-100"
          >
            <div className="hstack">
              <span className="fw-bold text-primary w-100">
                Selecionar imagem
              </span>
              <input
                className="d-none"
                id="upload-store-logo"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                onChange={(event) => selectStoreImage(event.target.files[0])}
              />
            </div>
          </label>
        </div>
      </div>

      <div className="border-bottom my-3"></div>

      <div className="vstack">
        <div className="vstack pb-2">
          <h3 className="fs-3 fw-bold m-0 p-0 text-primary">Link de acesso</h3>
          <span className="fs-7 text-body-secondary">
            Personalize o link que seus clientes usarão para acessar o cardápio
            do seu estabelecimento.
          </span>
        </div>

        <div className="hstack gap-1">
          <StyledTextField
            id="standard-start-adornment"
            InputProps={{
              startAdornment: (
                <span className="text-secondary">delivery.legal/</span>
              ),
            }}
            value={store.newLink === null ? store.link : store.newLink}
            onChange={(event) => updateStore({ newLink: event.target.value })}
          />

          <Tooltip title="Acessar estabelecimento">
            <button
              className="btn btn-primary-fill p-0 m-0 text-primary"
              onClick={() =>
                window.open(`https://delivery.legal/${store.link}`)
              }
            >
              <span className="material-symbols-outlined">store</span>
            </button>
          </Tooltip>

          <Tooltip title="Copiar link">
            <button
              className="btn btn-primary-fill p-0 m-0 text-primary"
              onClick={() => copyStoreLink()}
            >
              <span className="material-symbols-outlined">content_copy</span>
            </button>
          </Tooltip>
        </div>
      </div>

      <div className="border-bottom my-3"></div>

      <div className="vstack">
        <div className="vstack pb-2">
          <h3 className="fs-3 fw-bold m-0 p-0 text-primary">
            Personalize sua página
          </h3>
          <span className="fs-7 text-body-secondary">
            Selecione uma cor principal para destacar a página do seu
            estabelecimento.
          </span>
        </div>

        <div className="hstack gap-1 w-100">
          <input
            type="color"
            style={{ height: 42 }}
            className="border-2 border-secondary-subtle overflow-hidden rounded-1 rounded-2 w-50"
            onChange={(event) => setStoreColorHex(event.target.value)}
            value={store.newColor === null ? store.color : store.newColor}
          />
          <input
            type="text"
            className="w-50 form-control py-2"
            onChange={(event) => setStoreColorHex(event.target.value, true)}
            value={store.newColor === null ? store.color : store.newColor}
            maxLength={7}
            minLength={7}
          />
        </div>
      </div>

      <button
        className="btn btn-primary fw-bold w-100 mt-5"
        onClick={() => handleStoreInfos()}
      >
        Salvar alterações
      </button>
    </div>
  );
}
