import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GESTOR_TABS } from '../../../shared/StoreHeader/constants/header.constants';
import { Route, Switch } from 'react-router-dom';
import StoreHeader from '../../../shared/StoreHeader/StoreHeader';

export default function Painel() {
  return (
    <Router>
      <StoreHeader />
      <Switch>
        {GESTOR_TABS.map((route) => (
          <Route
            exact={true}
            key={route.id}
            path={route.url}
            children={route.component}
          />
        ))}
      </Switch>
    </Router>
  );
}
