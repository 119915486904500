import React from 'react';
import './AboutUs.scss';

import DeliveryLogo from '../../assets/Images/logo/logo.png';

export default function AboutUs() {
  return (
    <div className="about-us">
      <div className="about-us-container">
        <h2 className="about-us-title">Quem somos</h2>
        <div className="about-us__sections">
          <div className="about-us__sections__informative-texts">
            <p className="about-us__sections__informative-text-text">
              O projeto Delivery.Legal foi idealizado com o propósito de atender
              qualquer tipo de estabelecimento que trabalhe com entrega de
              produtos, seja uma lanchonete, mercado, farmácia ou outro tipo.
            </p>
            <p className="about-us__sections__informative-text-text">
              Oferecemos um sistema de cardápio próprio e diversas
              funcionalidades para o gerenciamento do seu estabelecimento e
              pedidos dos clientes.
            </p>
            <p className="about-us__sections__informative-text-text">
              O melhor disso tudo, é saber que você, dono do estabelecimento,
              consegue ter autonomia para personalizar o cardápio online com a
              cara do seu negócio.
            </p>
          </div>
          <img
            className="about-us__sections-logo"
            src={DeliveryLogo}
            alt="Logo Delivery.legal"
          />
        </div>
      </div>
    </div>
  );
}
