import React from 'react';
import './LoadingContent.scss';
import DeliveryLogo from '../../assets/Images/logo/small_logo.png';

function LoadingContent() {
  return (
    <div
      className=" w-100 h-100 hstack justify-content-center align-items-center position-absolute"
      style={{ height: '100vh', width: '100vw' }}
    >
      <img
        src={DeliveryLogo}
        alt="Delivery.legal logo"
        width={180}
        className="store-loading-animation"
      />
    </div>
  );
}

export default LoadingContent;
