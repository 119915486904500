import React, { useEffect, useState } from 'react';
import './Configuracao.scss';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Geral from './components/Geral';
import TempoEstimado from './components/TempoEstimado';
import HorarioLoja from './components/HorarioLoja';
import Endereco from './components/Endereco';
import TaxaDelivery from './components/TaxaDelivery';
import PaymentsAccept from './components/PaymentsAccept/PaymentsAccept';
import CupomDesconto from './components/Cupom';

export default function Configuracao() {
  const [routeNav, setRouteNav] = useState([
    {
      actived: true,
      path: '/painel/configuracao',
      exact: true,
      label: 'Geral',
      main: <Geral />,
    },
    {
      actived: false,
      path: '/painel/configuracao/horario',
      exact: true,
      label: 'Horário de funcionamento',
      main: <HorarioLoja />,
    },
    {
      actived: false,
      path: '/painel/configuracao/endereco',
      exact: true,
      label: 'Endereço',
      main: <Endereco />,
    },
    {
      actived: false,
      path: '/painel/configuracao/tempo',
      exact: true,
      label: 'Tempo estimado',
      main: <TempoEstimado />,
    },
    {
      actived: false,
      path: '/painel/configuracao/preco-entrega',
      exact: true,
      label: 'Preço de Entrega',
      main: <TaxaDelivery />,
    },
    {
      actived: false,
      path: '/painel/configuracao/pagamento',
      exact: true,
      label: 'Método de pagamento',
      main: <PaymentsAccept />,
    },
    {
      actived: false,
      path: '/painel/configuracao/cupom',
      exact: true,
      label: 'Cupons de desconto',
      main: <CupomDesconto />,
    },
  ]);

  useEffect(() => {
    handlerActiveRoute({ path: window.location.pathname });
    return () => {
      setRouteNav([]);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  function handlerActiveRoute(infs) {
    let temp = routeNav.map((el) => {
      el.actived = el.path === infs.path ? true : false;
      return el;
    });
    setRouteNav(temp);
  }

  return (
    <div className="configuracao">
      <Router>
        <h1 className="fw-bold pb-2">Configurações da Loja</h1>
        <div className="line-nav">
          {routeNav.map((item, index) => {
            return (
              <Link
                key={index}
                to={item.path}
                className={
                  item.actived ? 'card-config card-active' : 'card-config'
                }
                onClick={() => handlerActiveRoute(item)}
              >
                {item.label}
              </Link>
            );
          })}
        </div>
        <Switch>
          {routeNav.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={route.main}
            />
          ))}
        </Switch>
      </Router>
    </div>
  );
}
