import React from 'react';

function ClientAuthMethods({ sign, createAccount }) {
  return (
    <div className="vstack p-2">
      <h1 className="text-center fw-bold fs-4 mb-4 d-block">
        Já possui cadastro ?
      </h1>
      <button onClick={() => sign()} className="btn btn-primary w-100">
        Entrar
      </button>
      <span className="fs-8 text-center py-1">ou</span>
      <button
        onClick={() => createAccount()}
        className="btn btn-outline-primary w-100"
      >
        Criar conta
      </button>
    </div>
  );
}

export default ClientAuthMethods;
