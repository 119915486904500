import React, { useEffect, useState, useRef } from 'react';
import './Primary.scss';

import { toast } from 'react-toastify';

import * as Yup from 'yup';
import { Form } from '@unform/web';

import Textarea from '../../../../shared/Textarea/Textarea';
import DropDown from '../../../../shared/DropDown/DropDown';
import InputV2 from '../../../../shared/InputV2/InputV2';

import ImageDefault from '../../../../assets/Images/no-product-image.png';
import {
  deepClone,
  getItemInBrowserStorage,
  maskCurrencyBRL,
  removeMaskPrice,
} from '../../../../Utils/Index';
import {
  updateProduct,
  uploadPhotoProduct,
} from '../../../../services/Products';

import EventEmitter from '../../../../services/Event';
import { useFormProduct } from '../../ContextProduct/ContextProduct';
import { uploadStoreProductImage } from '../../../../firebase/storage';

function Primary({
  productData,
  categories,
  idCategoryToCreateProduct,
  saveProductData,
}) {
  const [idCategory, setIdCategory] = useState(0);
  const [idProduct, setIdProduct] = useState(0);

  const [imagePath, setImagePath] = useState('');
  const [imageSelected, setImageSelected] = useState('');
  const [fileFromImageProduct, setFileFromImageProduct] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [previousProductData, setPreviousProductData] = useState(null);

  const [disableButtons, setDisableButtons] = useState(false);

  const { setSidebar } = useFormProduct();

  const formRef = useRef(null);

  useEffect(() => {
    if (productData) {
      setPreviousProductData(productData);
      handleFormValue(productData);
      return;
    }
    if (idCategoryToCreateProduct) setIdCategory(idCategoryToCreateProduct);
  }, [productData]);

  useEffect(() => {
    return () => {
      setIdCategory(0);
      setIdProduct(0);
      setImagePath(null);
      setImageSelected(null);
      setFileFromImageProduct(null);
      setProductPrice(null);
      setPreviousProductData(null);
      setDisableButtons(false);
    };
  }, []);

  function handleFormValue(product) {
    const storeFirebasePath = getItemInBrowserStorage(
      'CLIENT_DATA',
      false
    ).firebase_path;
    Object.keys(product).forEach((field) => {
      formRef.current.setFieldValue(field, product[field]);
    });
    if (product) setIdProduct(product.codigo);
    setIdCategory(product.cod_categoria);
    setProductPrice(product.preco);
    if (product.imagem_local && product.imagem_token)
      setImagePath(
        `${process.env.REACT_APP_FIREBASE_URL}/estabelecimentos%2F${storeFirebasePath}%2Fprodutos%2F${product.imagem_local}?alt=media&token=${product.imagem_token}`
      );
  }

  async function handleValidityFormFields(field) {
    try {
      const productInfs = Yup.object().shape({
        ativo: Yup.boolean(),
        codigo: Yup.number(),
        nome: Yup.string().required('Informe o nome do produto'),
        descricao: Yup.string(),
        peso: Yup.string(),
        preco: Yup.string().required('Informe o valor do produto'),
      });

      await productInfs.validate(field, { abortEarly: false });
      formRef.current.setErrors({});
      saveProductDetails();
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  function saveProductImage(imageFile) {
    if (Boolean(imageFile) && imageFile.size <= 5242880) {
      const file = imageFile;
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) setImageSelected(reader.result);
      };
      reader.readAsDataURL(file);
      setFileFromImageProduct(imageFile);
    } else
      toast.error(
        'Ops! O tamanho da imagem é muito grande, a imagem deve ser de até 5MB.'
      );
  }

  function changeProductCategory(categorySelected) {
    const { codigo } = categorySelected[0];
    setIdCategory(codigo);
  }

  async function saveProductDetails() {
    const productData = getProductData();

    if (!verifyHasChange(productData)) {
      toast.warning('Não há alterações para serem salvas.');
      return;
    }
    callSaveProduct(productData);
  }

  function getProductData() {
    let product = deepClone(formRef.current.getData());
    product.cod_categoria = idCategory;
    product.codigo = idProduct;
    product.ativo = true;
    product.preco = removeMaskPrice(product.preco);
    return product;
  }

  function verifyHasChange(currentProductData) {
    if (imageSelected) return true;
    if (!previousProductData) return true;

    let someChangeInProductInfs = false;
    const fieldsToVerify = {
      ativo: true,
      codigo: true,
      nome: true,
      descricao: true,
      peso: true,
      preco: true,
      cod_categoria: true,
    };

    Object.keys(currentProductData).forEach((field) => {
      if (fieldsToVerify[field]) {
        if (currentProductData[field] !== previousProductData[field])
          someChangeInProductInfs = true;
      }
    });

    return someChangeInProductInfs;
  }

  async function callSaveProduct(productInfs) {
    setDisableButtons(true);
    const response = await updateProduct({ ...productInfs });

    if (!response || !response.success) {
      setDisableButtons(false);
      toast.error(
        'Ocorreu um erro ao criar o produto, tente novamente ou entre em contato conosco.'
      );
      return;
    }

    productInfs.new_product = Boolean(productInfs.codigo === 0);
    if (productInfs.codigo === 0) productInfs.codigo = response.result.id;

    if (fileFromImageProduct) {
      toast.success(
        `Produto ${productInfs.new_product ? 'criado' : 'editado'} com sucesso`
      );
      await uploadProductImage(fileFromImageProduct, productInfs);
      return;
    }

    EventEmitter.emit('update-products', productInfs);
    toast.success(
      `Produto ${productInfs.new_product ? 'criado' : 'editado'} com sucesso`
    );
    saveCurrentData(productInfs);
    setDisableButtons(false);
    return;
  }

  async function uploadProductImage(image, product) {
    const imageName = `PRODUTO=[${product.codigo}]`;
    const response = await uploadStoreProductImage(image, imageName);
    if (!response.success) {
      toast.error(
        `Ocorreu um erro ao ${
          product.new_product === 0 ? 'salvar' : 'editar'
        } a imagem do produto`,
        { autoClose: 8000 }
      );
      setTimeout(() => {
        toast.warn(response.msg, { autoClose: 8000 });
      }, 800);
      return;
    }

    const { imagem_local, imagem_token } = response;
    await uploadPhotoProduct(
      'produtos',
      product.codigo,
      imagem_local,
      imagem_token
    );

    EventEmitter.emit('update-products', product);
    toast.success(
      `Imagem ${product.new_product === 0 ? 'salva' : 'editada'} com sucesso`
    );
    saveProductData(product);
    setDisableButtons(false);
    return;
  }

  function saveCurrentData(productInfo) {
    productInfo.preco = maskCurrencyBRL(productInfo.preco);
    setFileFromImageProduct(null);
    setPreviousProductData(productInfo);
    handleFormValue(productInfo);
    setIdCategory(productInfo.cod_categoria);
    saveProductData(productInfo);
  }

  return (
    <div className="w-100 d-flex flex-column">
      <Form ref={formRef} onSubmit={handleValidityFormFields}>
        <div className="d-flex flex-md-row flex-column gap-md-3">
          <div className="d-flex flex-column gap-2 w-100">
            <div className="pt-1">
              <span className="input-content-label">Categoria</span>
              <DropDown
                items={categories}
                filterItems={changeProductCategory}
                msgAllCategory={false}
                personalizeWidth={true}
                defaultText="Escolha uma categoria"
                openvalue={idCategory}
              />
            </div>

            <InputV2
              label="Nome do produto"
              placeholder="Nome do produto"
              name="nome"
              type="text"
              maxLength={50}
            />

            <Textarea
              label="Descrição"
              name="descricao"
              type="text"
              maxLength={250}
              className="dl-textarea"
              labelCustomClass="w-100"
            />
          </div>

          <div className="d-flex flex-column gap-2 w-100">
            <div>
              <span className="input-content-label" style={{ marginBottom: 8 }}>
                Imagem do produto
              </span>
              <div className="upload-product-image__image">
                <div className="upload-product-image__image__container">
                  {!imageSelected && !imagePath && (
                    <img
                      src={ImageDefault}
                      style={{ width: '50%' }}
                      alt="Sem imagem do produto"
                    />
                  )}
                  {imageSelected && (
                    <img
                      src={imageSelected}
                      className="upload-product-image__image__container__image"
                      alt="Preview da imagem do produto"
                    />
                  )}
                  {imagePath && !imageSelected && (
                    <img
                      src={imagePath}
                      className="upload-product-image__image__container__image"
                      alt="Imagem do produto"
                    />
                  )}
                  <label
                    htmlFor="file-upload"
                    className={
                      imageSelected
                        ? 'upload-product-image__image__container__btn-upload'
                        : 'upload-product-image__image__container__btn-upload product__fields-section__right__image__container__btn-upload-has-image'
                    }
                    style={{ position: 'absolute', bottom: 10 }}
                  >
                    Carregar imagem
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => saveProductImage(e.target.files[0])}
                  />
                </div>
              </div>
            </div>

            <InputV2
              label="Peso"
              name="peso"
              type="text"
              placeholder="Peso"
              maxLength={9}
            />

            <InputV2
              label="Preço"
              name="preco"
              type="text"
              placeholder="Valor do produto"
              mask={'CURRENCY'}
              maxLength={10}
            />
          </div>
        </div>

        <div className="w-100 d-flex justify-content-sm-end justify-content-between py-2 gap-3">
          <button
            type="button"
            className={`btn btn-outline-danger ${
              disableButtons && 'disabled-btn'
            }`}
            onClick={() => setSidebar(false)}
            disabled={disableButtons}
          >
            Sair
          </button>
          <button
            type="submit"
            className={`btn btn-primary ${disableButtons && 'disabled-btn'}`}
            disabled={disableButtons}
          >
            Salvar
          </button>
        </div>
      </Form>
    </div>
  );
}

export default Primary;
