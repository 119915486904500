import Firebase from '../config/firebase';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { getItemInBrowserStorage } from '../Utils/Index';

const storage = getStorage(Firebase);
const storeData = getItemInBrowserStorage('CLIENT_DATA', false);
// TODO
// DELETAR IMAGEM ANTIGA QUANDO SUBIR UMA NOVA

export async function uploadStoreProductImage(image, imageName) {
  let response, storeRef;

  if (
    !storeData ||
    !storeData.cod_estabelecimento ||
    !storeData.nome_estabelecimento
  ) {
    return {
      success: false,
      msg: 'Não foi possível buscar os dados do estabelecimento, por favor faça login novamente.',
    };
  }

  try {
    storeRef = ref(
      storage,
      `estabelecimentos/${storeData.firebase_path}/produtos/${imageName}`
    );
  } catch (error) {
    response = {
      success: false,
      msg: 'Ocorreu um erro ao localizar a pasta para armazenamento da imagem, tente novamente ou entre em contato conosco.',
    };
  }

  try {
    await uploadBytes(storeRef, image).then(async () => {
      response = await getImageDownloadUrl(storeRef, imageName);
    });
  } catch (error) {
    response = {
      success: false,
      msg: 'Ocorreu um erro ao realizar o upload da imagem, por favor tente novamente ou entre em contato conosco.',
    };
  }

  return response;
}

export async function uploadStoreLogo(image) {
  let response, storeRef;

  if (!storeData || !storeData.nome_estabelecimento) {
    return {
      success: false,
      msg: 'Não foi possível buscar os dados do estabelecimento, por favor faça login novamente.',
    };
  }

  const imageName = storeData.nome_estabelecimento
    .toUpperCase()
    .replace(/ /g, '_');
  try {
    storeRef = ref(
      storage,
      `estabelecimentos/${storeData.firebase_path}/logo/${imageName}`
    );
  } catch (error) {
    response = {
      success: false,
      msg: 'Ocorreu um erro ao localizar a pasta para armazenamento da imagem, tente novamente ou entre em contato conosco.',
    };
  }

  try {
    await uploadBytes(storeRef, image).then(async () => {
      response = await getImageDownloadUrl(storeRef, imageName);
    });
  } catch (error) {
    response = {
      success: false,
      msg: 'Ocorreu um erro ao realizar o upload da imagem, por favor tente novamente ou entre em contato conosco.',
    };
  }

  return response;
}

async function getImageDownloadUrl(imagePath, imageName) {
  let response;
  const imageRef = ref(storage, imagePath);

  await getDownloadURL(imageRef)
    .then(async (url) => {
      response = {
        success: true,
        imagem_local: imageName,
        imagem_token: url.split('token=').pop(),
      };
    })
    .catch(() => {
      response = {
        success: false,
        msg: 'Ocorreu um erro ao finalizar o upload da imagem, por favor tente novamente ou entre em contato conosco.',
      };
    });
  return response;
}
