import React, { useState, useEffect, useContext } from 'react';
import './OrderDetails.scss';
import NoStorageImage from '../../../assets/Images/no-product-image.png';
import {
  getItemInBrowserStorage,
  maskCEP,
  maskCurrencyBRLWithoutSymbol,
  maskPhoneBR,
} from '../../../Utils/Index';
import { cancelOrder, getOrderDetail } from '../../../services/Consumer';
import { toast } from 'react-toastify';
import SkeletonOrderDetails from '../../Skeletons/SkeletonOrderDetails';
import { userDataContext } from '../../MenuProvider/MenuProvider';

function OrderDetails({ infsOrder, backToHistory, updateInfsOrder }) {
  const { historyOrdersDetails, setHistoryOrdersDetails } =
    useContext(userDataContext);

  useEffect(() => {
    verifyHasOrderInContext(infsOrder.codigo, infsOrder.cod_estabelecimento);
  }, [infsOrder]);

  const [store, setStore] = useState(null);
  const [payment, setPayment] = useState(null);
  const [address, setAddress] = useState(null);
  const [observationOrder, setObservationOrder] = useState(null);

  const [order, setOrder] = useState([]);

  const [loadedInfs, setLoadedInfs] = useState(false);
  const [canCancel, setCanCancel] = useState(true);

  function verifyHasOrderInContext(idOrder, idStore) {
    if (!historyOrdersDetails.length) {
      callGetInfsOrder(idOrder, idStore);
      return;
    }

    const ordersFromStore = historyOrdersDetails.find(
      (idStoreStored) => idStoreStored.idStore === idStore
    );

    if (!Boolean(ordersFromStore)) {
      callGetInfsOrder(idOrder, idStore);
      return;
    }

    const order = ordersFromStore.orders.find(
      (ordersStored) => ordersStored.loja.cod_order === idOrder
    );

    if (!Boolean(order)) {
      callGetInfsOrder(idOrder, idStore);
      return;
    }

    if (order.historico[0].cod_pedido_status > 2) setCanCancel(false);
    setStore(order.loja);
    setOrder(order.itens);
    setAddress(order.endereco);
    setPayment(order.pagamento);
    setLoadedInfs(true);

    if (order.historico[0].cod_pedido_status < 2) {
      callGetInfsOrder(idOrder, idStore, true);
      updateInfsOrder();
    }
  }

  async function callGetInfsOrder(idOrder, idStore) {
    const response = await getOrderDetail(idStore, idOrder);
    if (!response.success || !response) {
      toast.error(
        'Ocorreu um erro ao buscar as informações do pedido, tente novamente ou entre em contato conosco.'
      );
      return;
    }
    if (response.success) formatInfs(response.result);
  }

  function formatInfs(orderDetails) {
    let { loja, itens, endereco, pagamento, historico, obs } = orderDetails;

    if (historico[0].cod_pedido_status > 2) setCanCancel(false);

    loja.cod_order = infsOrder.codigo;
    loja.image = infsOrder.image;
    loja.whatsapp = maskPhoneBR(String(loja.whatsapp));

    endereco.enderece_completo = `${endereco.logradouro}, ${endereco.logradouro_nro}`;

    pagamento.valor_final = maskCurrencyBRLWithoutSymbol(pagamento.valor_final);
    pagamento.valor_pedido = maskCurrencyBRLWithoutSymbol(
      pagamento.valor_pedido
    );
    pagamento.valor_entrega = maskCurrencyBRLWithoutSymbol(
      pagamento.valor_entrega
    );
    pagamento.valor_desconto_cupom = maskCurrencyBRLWithoutSymbol(
      pagamento.valor_desconto_cupom
    );

    endereco.cep = maskCEP(endereco.cep);

    itens.map((order) => {
      order.preco_formatado = maskCurrencyBRLWithoutSymbol(order.preco);
      if (Boolean(order.complementos.length)) {
        order.complementos.forEach((complement) => {
          complement.preco_formatado = maskCurrencyBRLWithoutSymbol(
            complement.preco
          );
        });
      }
      return order;
    });
    saveOrderInContext(orderDetails);
    setStore(loja);
    setOrder(itens);
    setAddress(endereco);
    setPayment(pagamento);
    setObservationOrder(obs);
    setLoadedInfs(true);
  }

  function saveOrderInContext(orderDetails) {
    const newOrder = {
      idStore: infsOrder.cod_estabelecimento,
      orders: [orderDetails],
    };

    if (!historyOrdersDetails.length) {
      historyOrdersDetails.push(newOrder);
      setHistoryOrdersDetails(historyOrdersDetails);
      return;
    }

    const ordersFromStore = historyOrdersDetails.find(
      (storeStored) => storeStored.idStore === orderDetails.loja.codigo
    );

    if (!ordersFromStore) {
      historyOrdersDetails.push(newOrder);
      setHistoryOrdersDetails(historyOrdersDetails);
      return;
    }

    const order = ordersFromStore.orders.find(
      (ordersStored) =>
        ordersStored.loja.cod_order === orderDetails.loja.cod_order
    );

    if (!order) {
      ordersFromStore.orders.push(orderDetails);
      const indexStore = historyOrdersDetails.findIndex(
        (storeStored) => storeStored.idStore === orderDetails.loja.codigo
      );
      historyOrdersDetails[indexStore] = ordersFromStore;
      setHistoryOrdersDetails(historyOrdersDetails);
    }
  }

  async function callCancerOrder() {
    const response = await cancelOrder(store.cod_order);
    if (response.success) {
      updateInfsOrder();
      toast.success(
        'Pedido cancelado com sucesso, caso precise entre em contato conosco e de seu feedback!',
        4000
      );
    } else
      toast.error(
        'Ocorreu um erro ao cancelar o seu pedido, tente novamente ou entre em contato conosco.'
      );
    backToHistory(false);
  }

  function openChat() {
    const storeData = getItemInBrowserStorage('STORE_DETAILS', true);
    // toast.warning("Em breve :D" + );
    window.open(
      `https://wa.me/${storeData.settings.store.whatsapp}?text=Oi%2C%20gostaria%20de%20tirar%20uma%20d%C3%BAvida`,
      '_blank'
    );
  }

  return (
    <>
      {loadedInfs ? (
        <div className="order-details-content">
          <div className="section-container">
            <div className="section-container__infs-store">
              <div className="section-container__infs-store__left-section">
                <img
                  className="section-container__infs-store__left-section-logo"
                  src={store.image ? store.image : NoStorageImage}
                  alt="Logo estabelecimento"
                />
                <span className="section-container__infs-store__left-section-status">
                  <span className="section-container__infs-store__left-section-dot">
                    •
                  </span>
                  &nbsp;Aberto
                </span>
              </div>
              <div className="section-container__infs-store__right-section">
                <div className="section-container__infs-store__right-section__order">
                  <div>
                    <span className="section-container__infs-store__right-section__order-id">
                      #{store.cod_order}
                    </span>
                    <span className="section-container__infs-store__right-section__order-date">
                      {store.date}
                    </span>
                  </div>
                  <span className="section-container__infs-store__right-section__order-price">
                    <small>R$</small>&nbsp;{payment.valor_final}
                  </span>
                </div>
                <div className="section-container__infs-store__right-section__store">
                  <span className="section-container__infs-store__right-section__store-bold">
                    {store.nome}
                  </span>
                  <span className="section-container__infs-store__right-section__store-default">
                    {address.logradouro}, {address.logradouro_nro}
                  </span>
                </div>
                <div className="section-container__infs-store__right-section__store">
                  <span className="section-container__infs-store__right-section__store-default">
                    Contato
                  </span>
                  <span className="section-container__infs-store__right-section__store-bold">
                    {store.whatsapp}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="section-container"
            style={{ flexDirection: 'column' }}
          >
            <span className="section-container-title">Itens</span>
            {order.map((order, index) => {
              return (
                <div key={index} className="section-container__itens-order">
                  <div className="section-container__itens-order-product">
                    <span className="section-container__itens-order-name">
                      {order.qtd}x {order.nome}
                    </span>
                    <span className="section-container__itens-order-price">
                      <small>R$</small>
                      {order.preco_formatado}
                    </span>
                  </div>
                  <div>
                    {order.complementos.map((complemento, index) => {
                      return (
                        <div
                          className="section-container__itens-order-complement"
                          key={index}
                        >
                          <span>+ {complemento.nome}</span>
                          <span className="section-container__itens-order-price">
                            <small>R$</small>&nbsp;{complemento.preco_formatado}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          {observationOrder && (
            <div
              className="section-container"
              style={{ flexDirection: 'column' }}
            >
              <span className="section-container-title">observação</span>
              <span>{observationOrder}</span>
            </div>
          )}
          <div
            className="section-container"
            style={{ flexDirection: 'column' }}
          >
            <span className="section-container-title">Entrega</span>
            <span className="section-container-date">
              {address.logradouro}, {address.logradouro_nro} - {address.bairro}
            </span>
            <span className="section-container-date">{address.cidade} </span>
            <span className="section-container-date">{address.cep} </span>
          </div>
          <div
            className="section-container"
            style={{ flexDirection: 'column' }}
          >
            <span className="section-container-title">Pagamento</span>
            <div className="section-container-inline">
              <span className="section-container-date">Subtotal</span>
              <span className="section-container__itens-order-price">
                <small>R$</small>&nbsp;{payment.valor_pedido}
              </span>
            </div>
            <div className="section-container-inline">
              <span className="section-container-date">Taxa entrega</span>
              <span className="section-container__itens-order-price">
                <small>R$</small>&nbsp;{payment.valor_entrega}
              </span>
            </div>
            {Boolean(payment.cupom) && (
              <div className="section-container-inline">
                <span className="section-container-date">Desconto cupom</span>
                <span className="section-container__itens-order-price">
                  <small>R$</small>&nbsp;{payment.valor_desconto_cupom}
                </span>
              </div>
            )}
            <div className="section-container-inline" style={{ marginTop: 8 }}>
              <span className="section-container-date">Total</span>
              <span className="section-container__itens-order-price">
                <small>R$</small>&nbsp;{payment.valor_final}
              </span>
            </div>
            {Boolean(payment.troco) && (
              <div className="section-container-inline">
                <span className="section-container-date">Troco</span>
                <span className="section-container__itens-order-price">
                  <small>R$</small>&nbsp;{payment.troco}
                </span>
              </div>
            )}
          </div>

          <button
            className="section-container-contact"
            onClick={() => openChat()}
          >
            Entrar em contato
          </button>

          {canCancel && (
            <button
              className="section-container-cancel-order"
              onClick={() => callCancerOrder()}
            >
              Solicitar cancelamento
            </button>
          )}
        </div>
      ) : (
        <SkeletonOrderDetails />
      )}
    </>
  );
}

export default OrderDetails;
