import React from 'react';
import './App.scss';
import LandingPage from './LandingPage/LandingPage';
import Painel from './painel/Configuracao/components/Painel';
import Representante from './SalesRepresentative/SalesRepresentative';
import Menu from './menu/Menu';
import ConclueOrder from './menu/Cart/ConclueOrder/ConclueOrder';
import Page404 from './shared/Page404';
import ConfigureStore from './ConfigureStore/ConfigureStore';
import MenuProvider from './menu/MenuProvider/MenuProvider';
import AuthenticationProvider from './Authentication/Provider/AuthenticationProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import StoreAuthentication from './Authentication/Store/StoreAuthentication';

export default function App() {
  return (
    <React.Fragment>
      <Router>
        <AuthenticationProvider>
          <MenuProvider>
            <Switch>
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
              <Route exact path="/home" component={LandingPage} />
              <Route exact path="/login" component={StoreAuthentication} />
              <Route path="/painel" component={Painel} />
              <Route path="/primeiro-acesso" component={ConfigureStore} />
              <Route path="/representante" component={Representante} />
              <Route path="/pedido/finalizar/:id" component={ConclueOrder} />
              <Route path="/:id" component={Menu} />
              <Route path="/pagina-nao-encontrada" component={Page404} />
            </Switch>
          </MenuProvider>
        </AuthenticationProvider>
      </Router>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
}
