import React from 'react';

function ConfigurationSteps({ currentStepIndex }) {
  const steps = [
    'Estabelecimento',
    'Escala',
    'Endereço',
    'Entrega/retirada',
    'Frete',
    'Pagamentos',
  ];

  return (
    <ul className="d-flex flex-row flex-wrap p-0 m-0 gap-1">
      {steps.map((step, index) => {
        return (
          <div key={index} className="hstack align-items-center">
            <li
              className={`p-0 m-0 min-w-fit list-group-item fs-9 fw-bold ${
                currentStepIndex === index
                  ? 'text-primary'
                  : currentStepIndex > index
                  ? 'text-success'
                  : 'text-secondary'
              }`}
            >
              {step}
            </li>
            {index !== steps.length - 1 && (
              <span className="material-symbols-outlined fs-8 text-secondary">
                chevron_right
              </span>
            )}
          </div>
        );
      })}
    </ul>
  );
}

export default ConfigurationSteps;
