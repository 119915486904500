import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from '../../../Utils/Index';
import './FilterStatusOrder.scss';
import DropDown from '../../../shared/DropDown/DropDown';

export default function FilterStatusOrder({
  allOrders,
  showOrderWithStatus,
  noOrders,
  forceFilter = null,
}) {
  const [filterOrders, setFilterOrders] = useState([
    {
      codigo: -1,
      subStatus: 0,
      cancelStatus: 0,
      nome: 'Todos',
      selected: true,
    },
    {
      codigo: 1,
      subStatus: 0,
      cancelStatus: 0,
      nome: 'Aguardando',
      selected: false,
    },
    {
      codigo: 2,
      subStatus: 0,
      cancelStatus: 0,
      nome: 'Em preparo',
      selected: false,
    },
    {
      codigo: 3,
      subStatus: 4,
      cancelStatus: 0,
      nome: 'Liberado',
      selected: false,
    },
    {
      codigo: 5,
      subStatus: 0,
      cancelStatus: 0,
      nome: 'Finalizado',
      selected: false,
    },
    {
      codigo: 6,
      subStatus: 7,
      cancelStatus: 8,
      nome: 'Cancelado',
      selected: false,
    },
  ]);
  const [infsOrdersOriginal, setInfsOrdersOriginal] = useState([{}]);
  const [useMobileHeaderOptions, setUseMobileHeaderOptions] = useState(false);
  const [filterSelected, setFilterSelected] = useState(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setUseMobileHeaderOptions(width < 1024);
  }, [width]);

  useEffect(() => {
    setInfsOrdersOriginal(allOrders);
    if (forceFilter)
      handlerFilterStatus(
        filterOrders.find(
          (el) =>
            el.codigo === forceFilter ||
            el.subStatus === forceFilter ||
            el.cancelStatus === forceFilter
        ),
        false
      );
    if (filterSelected && filterSelected.codigo !== -1 && !forceFilter)
      handlerFilterStatus(
        filterOrders.find(
          (el) =>
            el.codigo === filterSelected.codigo ||
            el.subStatus === filterSelected.codigo ||
            el.cancelStatus === filterSelected.codigo
        ),
        false
      );
    return () => {
      setInfsOrdersOriginal(null);
      setFilterSelected(null);
    };
  }, [allOrders, forceFilter]); //eslint-disable-line react-hooks/exhaustive-deps

  function handlerFilterStatus(statusOrder, isDropDown = true) {
    let temp = [{}];
    if (statusOrder.codigo) setFilterSelected(statusOrder);

    if (!isDropDown) {
      temp = filterOrders.map((el) => {
        el.selected = el.codigo === statusOrder.codigo;
        return el;
      });
      setFilterOrders(temp);
      showFilterOrders(statusOrder);
    } else if (isDropDown && useMobileHeaderOptions) {
      showFilterOrders(statusOrder[0]);
    }
  }

  function showFilterOrders(statusType) {
    if (statusType.codigo === -1) {
      showOrderWithStatus(infsOrdersOriginal);
      if (infsOrdersOriginal.length === 0) noOrders(true);
      else noOrders(false);
    } else {
      let orderFiltered = infsOrdersOriginal.filter(
        (el) =>
          el.cod_pedido_status === statusType.codigo ||
          el.cod_pedido_status === statusType.subStatus ||
          el.cod_pedido_status === statusType.cancelStatus
      );
      if (orderFiltered.length === 0) noOrders(true);
      else noOrders(false);
      showOrderWithStatus(orderFiltered, {
        code: statusType.codigo,
        subCode: statusType.subStatus,
      });
    }
  }

  return (
    <>
      {useMobileHeaderOptions ? (
        <div className="align-items-center justify-content-center vstack w-100">
          <span className="fs-3 fw-bold m-0 p-0">Filtrar pedidos</span>
          <DropDown
            items={filterOrders}
            filterItems={handlerFilterStatus}
            msgAllCategory={false}
            openvalue={-1}
            personalizeWidth={true}
          />
        </div>
      ) : (
        <div className="hstack m-0 mb-md-3">
          <div className="w-100 hstack justify-content-between rounded shadow-sm p-2 align-items-center">
            {filterOrders.map((filter, index) => {
              return (
                <button
                  key={index}
                  className={`btn btn-primary-fill fs-6 w-100 h-100 align-items-center jusitfy-cotent-center p-0 m-0 ${
                    filter.selected ? 'bg-primary text-white fw-bold' : ''
                  }`}
                  onClick={() => handlerFilterStatus(filter, false)}
                >
                  {filter.nome}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
