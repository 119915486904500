import React, { memo, useEffect, useState } from 'react';
import './ProductCard.scss';
import NoProductImage from '../../assets/Images/no-product-image.png';

function ProductCard({ product, getProductInfs }) {
  const [image, setImage] = useState(NoProductImage);

  useEffect(() => {
    setImage(product.image);
  }, [product]);

  return (
    <div className="card-product" onClick={() => getProductInfs(product)}>
      <div className="image-content">
        <img
          className="image-product"
          src={image}
          alt="Imagem do item"
          onError={() => setImage(NoProductImage)}
        />
      </div>
      <div className="details-product">
        <span className="name">{product.nome}</span>
        <span className="description">{product.descricao}</span>
        <span className="price">
          <small>R$</small>
          {product.price_formated}
        </span>
      </div>
    </div>
  );
}

export default memo(ProductCard);
